<template>
    <span>{{ outputValue }}</span>
</template>

<script>
import {shallowRef} from 'vue'
import {adaptDateFormat} from '../../../lib/utility'
import {format, toDate} from '../../../../node_modules/date-format-parse'

export const languageImport = (locale) => {
    let locale_lc = locale.toLowerCase().replace('_', '-')
    return import('vue-datepicker-next/locale/' + locale_lc + '.js')
        .catch(e => {
            if (locale_lc.length > 2) {
                return import('vue-datepicker-next/locale/' + locale_lc.substr(0,2) + '.js')
            } else throw e
        })
        .catch(e => import('vue-datepicker-next/locale/en.js'))
}


export default {
    name: "DateFormat",
    props: {
        value: [String, Number, Date],
        format: String,
        locale: String
    },
    setup() {
        return {
            language: shallowRef({})
        }
    },
    computed: {
        defaultFormat () {
            return this.$root.settings.DATE_FORMAT || 'Y-m-d H:i:s'
        },
        outputValue () {
            if (!this.value || this.value === '-') return '-'
            const value = this.value instanceof Date ? this.value : toDate(isNaN(this.value) ?
                this.value.replace(/(\d{4}-\d{2}-\d{2}) (\d{2}:\d{2}:\d{2})/, '$1T$2') : this.value)

            return (value && !isNaN(value)) ? format(
                value,
                adaptDateFormat(this.format || this.defaultFormat),
                {locale: this.language && this.language.formatLocale}
            ) : 'invalid'
        }
    },
    created() {
        languageImport(this.locale || this.$root.systemLocale)
            .then(a => this.language = a.default)
    },
    watch: {
        locale(locale) {
            languageImport(this.locale || this.$root.systemLocale)
                .then(a => this.language = a.default)
        }
    }
}
</script>