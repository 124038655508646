<template>
    <FontAwesomeIcon
        class="i"
        :icon="['fas', icon]"
        :spin="spin"
    />
</template>

<script>
import FontAwesomeIcon from '../../lib/fontawesome'
export default {
    name: 'IconSolid',
    components: {FontAwesomeIcon},
    props: {
        icon: {
            type: String,
            required: true
        },
        spin: {
            type: Boolean,
            default() { return false }
        }
    }
}
</script>