const Collection = require('./collection')

/**
 */
class Language {
    /**
     * @param {Object} [data]
     */
    constructor(data) {
        this.locale = null
        this.name = null
        this.localName = null
        this.completeName = null

        if (data && Object.keys(data).length > 0) this.set(data)
    }

    /**
     * @param {Object} data
     */
    set (data) {
        if (typeof data === 'string') {
            this.locale = data
            this.name = this.localName = this.completeName = Language.languageName(data)
        } else {
            this.locale = data.Locale || data.locale || ''
            this.name = data.Name || data.name || ''
            this.localName = data.LocalName || data.localName || ''
            this.completeName = data.CompleteName || data.completeName || 0
        }
    }

    /**
     * @return {string}
     */
    get id() {
        return this.locale
    }

    /**
     * @param {string} locale
     */
    set id(locale) {
        this.locale = locale
    }

    /**
     * @return {string}
     */
    get title() {
        // return this.completeName || (this.localName + ' [' + this.name + ']')
        return this.localName
    }

    /**
     * @param {string} locale
     * @return {string}
     */
    static languageName(locale) {
        let sys_locale = this.sys_locale.replace(/^(a-z)/i, '$1')
        locale = (locale.replace(/^.*?(?:_([a-z]+))?$/i, '$1') || locale).toUpperCase()

        const dn = new Intl.DisplayNames([sys_locale], {type: 'language'})
        return dn.of(locale)
    }
    static sys_locale = window?.navigator?.language || 'en'
}

/**
 */
class LanguageCollection extends Collection {
    /**
     * @param {Object} element
     * @return {Language}
     */
    castObject(element) {
        return (element instanceof Language) ? element : new Language(element)
    }
    toString() {
        return this.valueOf().join(',')
    }
    toOptions() {
        return this.map((language) => ({
            value: language.locale,
            title: language.completeName,
            description: language.localName//?
        }))
    }
}

module.exports = {
    Language,
    LanguageCollection
}