<template>
    <div class="form-section-container">
        <div class="form-section-title" v-if="typeof label !== 'undefined'">
            {{ label }}
            <div v-if="buttonOnTopShown" class="float-right"><slot name="buttonsOnTop" /></div>
        </div>
        <div class="form-section-content d-flex">
            <div class="form-section-elements">
                <slot/>
            </div>
            <div v-if="$slots.info"
                class="form-section-info"
                :class="hideInformation ? 'hide' : null"
            >
                <div
                    class="toggle-btn"
                    @click="hideInformation = !hideInformation"
                    v-tooltip="hideInformation ? this.$root.l10n('show_information') : this.$root.l10n('hide_information')"
                >
                    <IconLight icon="chevron-double-right"/>
                </div>
                <div class="form-section-info-headline">{{ this.$root.l10n('information') }}</div>
                <div class="form-section-info-content">
                    <slot name="info"/>
                </div>
            </div>
        </div>
        <div class="form-section-buttons" v-if="$slots.buttons">
            <slot name="buttons"/>
        </div>
    </div>
</template>

<script>
import IconLight from "@/components/utility/IconLight";

export default {
    name: "FormSection",
    components: {IconLight},
    props: {
        label: String
    },
    data() {
        return {
            buttonOnTopShown: false,
            hideInformation: false
        }
    },
    mounted(){
        this.buttonOnTopShown = !!this.$slots['buttonsOnTop']
    }
}
</script>