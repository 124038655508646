<template>
    <svg v-if="icon"
        fill="currentColor" xmlns="http://www.w3.org/2000/svg"
        class="i custom-icons"
        :viewBox="customIcon.viewbox"
    >
        <path v-for="(path, ix) in customIcon.paths" :d="path" :key="ix"/>
    </svg>
</template>

<script>
const icons = require('../../../static/customIcons.json')

export default {
    name: 'IconCustom',
    props: {
        icon: String
    },
    computed: {
        customIcon() {
            return icons.find(i => {return i.name === this.icon})
        }
    }
}
</script>