<template>
    <div class="alert-box" :class="[messageClass , animate ? 'animate-shake' : null , disappear ? 'animate-disappear' : null]">
        <div class="alert-box-left">
            <IconSolid :icon="icon" v-tooltip="iconTitle"/>
        </div>
        <div class="alert-box-right">
            <div class="alert-box-title" v-if="title">{{title}}</div>
            <div v-if="text" class="alert-box-info" v-html="text"></div>
            <div v-if="$slots.default" class="alert-box-info"><slot/></div>
        </div>
        <slot name="button"/>
    </div>
</template>

<script>
    import IconSolid from "./IconSolid";

    export default {
        name: "AlertBox",
        components: {
            IconSolid
        },
        props: {
            type: {
                type: String,
                default: 'info'
            },
            icon: {
                type: String,
                default: 'info'
            },
            iconTitle: String,
            title: String,
            text: String,
            animate: {
                type: Boolean,
                default: false
            },
            disappear: {
                type: Boolean,
                default: false
            },
        },
        computed: {
            messageClass () {
                return 'alert-box-' + this.type
            }
        }
    }
</script>