<template>
    <div id="headerToolbar">
        <div id="userMenuWrapper" class="dropdown-menu-container" v-click-outside="hideDropdown">
            <div v-if="isPublicAccess" id="user-login-btn">
                <ButtonStd :primary="true" class="btn-medium active" @click="gotoLogin">{{$root.l10n('login')}}</ButtonStd>
            </div>
            <div v-else
                id="user-menu-btn"
                class="dropdown-menu-btn btn-big"
                v-tooltip="$root.l10n('open_user_menu')"
                @click="showDropdown = !showDropdown"
                v-bind="$root.addTestLabel('user-menu-btn')"
            >
                <div id="userPic" :style="'background-image: url(' + userPic + ')'"></div>
            </div>
            <UserMenu v-if="showDropdown" @clickMenuItem="hideDropdown"/>
        </div>
        <UserSimulate v-if="simulateEnabled" />
        <NotificationDropdown />
    </div>
</template>

<script>
import {directive as ClickOutside} from 'click-outside-vue3'
import UserSimulate from "./UserSimulate"
import UserMenu from "./UserMenu.vue";
import NotificationDropdown from "./NotificationDropdown"
import ButtonStd from '../../forms/ButtonStd'

const {User} = require('../../../lib/models/user')

export default {
    name: "Toolbar",
    components: {ButtonStd, UserMenu, UserSimulate, NotificationDropdown },
    data: function(){
        return {
            pic404: false,
            showDropdown: false
        }
    },
    computed: {
        userPic () {
            const userImageStorage = this.$root.CONSTANTS.IMAGE_PATHS.USER
            const {imagePath, gender} = this.$root.user
            const basePath = process.env.BASE_URL
            const assetPath = 'assets/manikin/'
            if (imagePath && !this.pic404) {
                const pic = (imagePath.indexOf('/') > -1 ? basePath : userImageStorage) + imagePath
                this.preloadPic(pic)
                return pic
            } else if (gender === User.GENDER_MALE) {
                return basePath + assetPath + 'male.svg'
            } else if (gender === User.GENDER_FEMALE) {
                return basePath + assetPath + 'female.svg'
            } else {
                return basePath + assetPath + 'unknown.svg'
            }
        },
        isPublicAccess() {
            return !!this.$root.publicAccess
        },
        simulateEnabled () {
            return this.$root.simulator
                || this.$root.rights.SIMULATE_USER
                || this.$root.absences?.length > 0
        }
    },
    directives: {
        ClickOutside
    },
    methods: {
        gotoLogin() {
            this.$root.goToRoute(this.$root.getRoute('login.form'))
        },
        preloadPic(pic) {
            this.pic404 = false
            const img = new Image(pic)
            img.src = pic
            img.onload = () => this.pic404 = !img.complete || img.naturalHeight === 0
            img.onerror = () => this.pic404 = true
        },
        closeDropdownsOnEsc: function(e) {
            if (e.keyCode === 27) this.hideDropdown()
        },
        hideDropdown(){
            this.showDropdown = false
        }
    },
    mounted() {
        document.addEventListener('keydown', this.closeDropdownsOnEsc)
    },
    unmounted() {
        document.removeEventListener('keydown', this.closeDropdownsOnEsc)
    },
}
</script>