/**
 *
 */
class ErrorWrapper extends Error {
    /**
     * @param {Error|XMLHttpRequest|String} error
     * @param {any?} data
     */
    constructor(error, data) {
        if (error && typeof error === 'object') {
            super()
            if (error.response) {
                try {
                    error.responseJSON = JSON.parse(error.response)
                } catch(e) {/*eslint no-empty: "error"*/}
            }
            this.message = error.responseJSON?.message || error.statusText || error.message || ''
            data = data || error
        } else super(error)

        this.data = (data instanceof ErrorWrapper && data.data) ||
            (data instanceof XMLHttpRequest && new ResponseError(data)) ||
            (data instanceof Error && data.stack) || data
    }
}

class ResponseError extends Error {
    /**
     * @param {XMLHttpRequest} xhr
     */
    constructor (xhr) {
        super()
        if (!xhr.status) {
            this.message = 'connection cannot be established'
            this.code = this.body = null
        } else try {
            const json = JSON.parse(xhr.response)
            this.code = json?.code || xhr.status
            this.message = json.message || xhr.statusText
            this.body = json?.response || xhr.response
        } catch (e){
            this.code = xhr.status
            this.message = xhr.statusText
            this.body = xhr.response
        }
        this.name = this.constructor.name
        this.target = xhr?.responseURL || null
    }

    /**
     * @returns {string}
     */
    toString() {
        return `${this.name}${this.code ? '(' + this.code + ')' : ''}: ${this.message}` + "\n" +
            (this.target ? "    URL: " + this.target + "\n" : '') +
            this.stack.split("\n").slice(1).join("\n") + (this.body ? "\n\n" + this.body : '')
    }
}

ErrorWrapper.Construction = class ConstructionError extends ErrorWrapper {}
ErrorWrapper.NotFound = class NotFoundError extends ErrorWrapper {}
ErrorWrapper.Response = ResponseError

module.exports = ErrorWrapper