const Collection = require('./collection')

class User {
    static GENDER_MALE = 1
    static GENDER_FEMALE = 2

    static DISABLED = {
        OFF: 1,
        APPLICANT: 2,
        WAITING: 3
    }

    /**
     * @param {Object} [data]
     */
    constructor (data) {
        this.id = null
        this.username = null
        this.createDate = null
        this.changeDate = null
        this.disabled = null
        this.deleted = null
        this.email = null
        this.title = null
        this.isAdmin = null
        this.gender = null
        this.imagePath = null
        this.firstName = null
        this.lastName = null
        this.usergroup = null
        this.name = null
        this.selected = null

        this.company = null
        this.country = null
        this.city = null
        this.zip = null
        this.address = null
        //this.completeName = null
        //this.lastLogin = null
        //this.lastLogout = null

        if (data && Object.keys(data).length > 0) this.set(data)
    }

    /**
     * @param {Object} data
     */
    set (data) {
        this.id = parseInt(data.Id || data.id, 10) || null
        this.username = data.UserName || data.username || ''
        this.email = data.Email || data.email || ''
        this.firstName = data.FirstName || data.firstName || ''
        this.lastName = data.LastName || data.lastName || ''
        this.name = data.CompleteName || data.Name || data.name
            || (this.firstName + ' ' + this.lastName).trim()
            || ('User #' + (this.id || 'null'))

        this.isAdmin = data.IsAdmin || data.isAdmin || 0
        this.gender = parseInt(data.Gender || data.gender, 10) || 0
        this.title = data.Title || data.title || ''
        this.imagePath = data.ImagePath || data.imagePath || data.imagepath || null

        this.usergroup = data.Usergroup || data.usergroup || null
        this.createDate = data.CreateDate || data.createDate || null
        this.changeDate = data.ChangeDate || data.changeDate || null
        this.disabled = parseInt(data.Disabled || data.disabled, 10) || 0
        this.deleted = parseInt(data.Deleted || data.deleted, 10) || 0

        this.company = data.Company || data.company || ''
        this.country = data.Country || data.country || ''
        this.city = data.City || data.city || ''
        this.zip = data.Zip || data.zip || ''
        this.address = data.Address || data.address || ''

        const selected = data.Selected || data.selected
        if (selected !== undefined) this.selected = selected || false
    }

    get created () { return this.createDate }
    get changed () { return this.changeDate }

    /**
     * @returns {number}
     */
    get state () {
        return this.deleted + (this.disabled << 1)
    }

    /**
     * @param {number} state
     * @returns {boolean}
     */
    checkState(state) {
        if (state === 0) {
            return this.state === state
        } else return (this.state & state) === state
    }
}
Object.freeze(User.DISABLED)
/**
 *
 */
class UserCollection extends Collection {
    /**
     * @param {Object} element
     * @returns {User}
     */
    castObject(element) {
        return (element instanceof User) ? element : new User(element)
    }
}

module.exports = {
    User,
    UserCollection
}