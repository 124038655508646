<template>
    <div id="navWrapper" class="bg-branding_primary">
        <ul id="nav" v-resize-observer="handleDropdownElements" ref="nav">
            <li v-for="(item, ix) in visibleItems"
                :key="ix"
                class="nav-item"
                :class="item.class || null"
                ref="navItems"
            >
                <NaviItem
                    :name="item.name"
                    :icon="item.icon"
                    :path="item.href"
                    :route="item.route"
                    :active="currentPath.indexOf(item.href) === 0"
                    @go="(...args) => item.click ? item.click() : switchActiveItem(...args)"
                    :tooltip="item.tooltip"
                    v-bind="$root.addTestLabel('nav-'+item.name)"
                />
            </li>
        </ul>
        <Dropdown
            v-if="hiddenItems.length > 0"
            :align="ddAlign.leftBottom"
        >
            <template #toggler="{toggle}">
                <div class="btn-std btn-icon-only dropdown-menu-btn" @click="toggle">
                    <IconLight icon="ellipsis-h" />
                </div>
            </template>
            <template #list>
                <li v-for="(item, ix) in hiddenItems"
                    :key="ix"
                    class="nav-item"
                    ref="navItems"
                >
                    <NaviItem
                        :name="item.name"
                        :icon="item.icon"
                        :path="item.href"
                        :route="item.route"
                        :active="currentPath.indexOf(item.href) === 0"
                        @go="(...args) => item.click ? item.click() : switchActiveItem(...args)"
                        v-bind="$root.addTestLabel('nav-'+item.name)"
                    >{{ item.name || item.href }}</NaviItem>
                </li>
            </template>
        </Dropdown>
    </div>
</template>

<script>
import {defineAsyncComponent} from 'vue'
import NaviItem from './NaviItem.vue';
import Dropdown, {ddAlign} from '../../../utility/Dropdown'
import IconLight from '../../../utility/IconLight'
import resizeObserver from "@/lib/mixins/resizeObserver"

const PrivacyContent = defineAsyncComponent(() => import('../../../utility/PrivacyContent'))

export default {
    name: "Navi",
    components: {
        NaviItem,
        Dropdown,
        IconLight
    },
    props: {
        items: Array,
        currentPath: String
    },
    directives: {
        resizeObserver
    },
    setup() {
        return {
            ddAlign
        }
    },
    data() {
        return {
            visibleCn: this.items.length + 1,
            actions: [

                    {
                        id          : 1,
                        name        : this.$root.l10n('imprint'),
                        description : this.$root.l10n('imprint_description'),
                        primary     : true,
                        icon        : 'pen-nib',
                        route       : 'engine.imprint',
                        data        : {
                            headline: this.$root.l10n('imprint'),
                            content: {
                                component: PrivacyContent,
                                args: {
                                    modal: true,
                                    type: this.$root.CONSTANTS.PRIVACY_TYPES.IMPRINT
                                }
                            }
                        }
                    },
                    {
                        id          : 2,
                        name        : this.$root.l10n('privacy_policy'),
                        description : this.$root.l10n('privacy_policy_description'),
                        primary     : true,
                        icon        : 'user-shield',
                        route       : 'engine.privacy',
                        data        : {
                            headline: this.$root.l10n('privacy_policy'),
                            content: {
                                component: PrivacyContent,
                                args: {
                                    modal: true,
                                    type: this.$root.CONSTANTS.PRIVACY_TYPES.PRIVACY
                                }
                            }
                        }
                    },
                    {
                        id          : 3,
                        name        : this.$root.l10n('ticketing'),
                        description : this.$root.l10n('ticketing_description'),
                        primary     : true,
                        icon        : 'life-ring',
                        data        : {
                            headline: this.$root.l10n('ticketing'),
                            content: {
                                component: PrivacyContent,
                                args: {
                                    modal: true,
                                    type: this.$root.CONSTANTS.PRIVACY_TYPES.TICKETING
                                }
                            }
                        }
                    }
            ]
        }
    },
    computed: {
        extendItems () {
            return this.items.concat([{
                id: 'id',
                name:  this.$root.l10n('info'),
                icon: 'info-circle',
                href: null,
                route: null,
                tooltip: this.$root.l10n('tooltip_info'),
                class: 'nav-item-info',
                click: () => this.showPrivacyModal(),
            }])
        },
        visibleItems() {
            return this.extendItems.slice(0, this.visibleCn)
        },
        hiddenItems() {
            return this.extendItems.slice(this.visibleCn)
        },
    },
    methods: {
        switchActiveItem (path, route) {
            if (route === 'customLink') window.open(path)
            else this.$root.goToRoute(path)
        },
        showPrivacyModal() {
            this.$root.showModal(
                this.actions[0].id,
                this.actions[0].data,
                this.actions
            )
        },
        handleDropdownElements() {
            this.visibleCn = this.extendItems.length
            this.$nextTick(() => this.rearrange())
        },
        rearrange() {
            let nav = this.$refs.nav
            let navItems = this.$refs.navItems
            let sumHeight = 0, total = 0
            const maxHeight = nav.clientHeight

            for (let navItem of navItems) {
                sumHeight += navItem.clientHeight

                if (sumHeight > maxHeight) {
                    this.visibleCn = total
                    break
                } else total++
            }
        },
    },
    watch: {
        items() {
            this.handleDropdownElements()
        }
    }
}
</script>