<template>
    <a
        :href="path"
        :class="active ? 'active' : null"
        @click.prevent="go"
        v-tooltip.right="{content: tooltipOutput, html: true}"
    >
        <IconThin :icon="icon || 'external-link'"/>
        <slot />
    </a>
</template>

<script>
import IconThin from "@/components/utility/IconThin"

export default {
    name: "NaviItem",
    components: {IconThin},
    props: {
        name: String,
        route: String,//??
        path: {
            type: String,
            default: '#'
        },
        icon: String,
        active: Boolean,
        tooltip: String
    },
    computed: {
        tooltipOutput() {
            return '<span class="tooltip-label">' + this.name + '</span> ' + this.tooltip
        }
    },
    methods: {
        go () {
            this.$emit('go', this.path, this.route)
        }
    }
}
</script>