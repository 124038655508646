<template>
    <AutoCompleteInput
        class="user-input"
        :modelValue="modelValue"
        :label="label"
        :id="id"
        :name="name"
        :list-label="listLabel"
        :help-text="helpText"
        :inline="inline"
        :multiple="multiple"
        :disabled="disabled"
        :required="required"
        :readonly="readonly"
        :preload="preload"
        :preview="imageBasePath !== null"
        :placeholder="placeholder"
        previewMode="user"
        :icon="icon"
        :imageBasePath="imageBasePath"
        :requestURL="target"
        :requestFilter="filter"
        :excludedId="excludedId"
        :parseOption="parseEntity"
        :searchText="parseSearch"
        :actions="actions"
        :errors="errors"
        :default-resettable="defaultResettable"
        @update:modelValue="$emit('update:modelValue', $event)"
        @open="$emit('open')"
        ref="autoCompleteInput"
    >
        <slot />
        <template #option="{option}">
            <span class="multiselect__flex">
                <div class="multiselect__flex-image">
                    <ItemPreview :path="$root.CONSTANTS.IMAGE_PATHS.USER" :file="option.imagePath || option.object?.ImagePath" :icon="null" previewMode="user">
                        <IconCustom :icon="genderIcon(option.object?.Gender)" />
                    </ItemPreview>
                </div>
                <div class="multiselect__flex-data">
                    <span class="text-overflow-element selected-title">
                        {{ option.title }}
                    </span>
                    <span class="text-overflow-element selected-sub-title">
                        {{ option.subTitle }}
                    </span>
                </div>
            </span>
        </template>
        <template #preview="{el}">
            <div v-if="preview" class="static-cell preview-cell" :class="typeof el.object?.Gender != 'undefined' || typeof el.__data?.Gender != 'undefined' ? 'preview-user' : ''">
                <ItemPreview :path="$root.CONSTANTS.IMAGE_PATHS.USER" :file="el.object ? el.object?.ImagePath : el.__data?.ImagePath" :icon="null" previewMode="user">
                    <IconCustom :icon="genderIcon(el.object?.Gender)" />
                </ItemPreview>
            </div>
        </template>
        <template #previewText="{el}">
            <div class="flex-cells-wrapper">
                <div role="cell" class="flex-cell">
                    <div class="media-item-attribute ">
                        <TextOverflow class="selected-title">{{ el.title }}</TextOverflow>
                        <TextOverflow v-if="el.subTitle || el.object?.Email" class="selected-sub-title" v-html="el.subTitle || el.object?.Email" />
                    </div>
                </div>
            </div>
        </template>
        <template #info><slot name="info" /></template>
        <template #actions="props"><slot name="actions" v-bind="props" /></template>
        <template #before-input><slot name="before-input" /></template>
        <template #after-each-selected="props">
            <slot name="after-each-selected" v-bind="props" />
        </template>
    </AutoCompleteInput>
</template>

<script>
import AutoCompleteInput from "./AutoCompleteInput"
import ItemPreview from '@/components/layout/content/items/ItemPreview.vue'
import IconCustom from '@/components/utility/IconCustom.vue'
import TextOverflow from '@/components/utility/TextOverflow.vue'
export default {
    name: "UserInput",
    components: {
        TextOverflow,
        IconCustom, ItemPreview,
        AutoCompleteInput
    },
    props: {
        modelValue: [Array, Object, String],
        label: String,
        id: [Number, String],
        name: String,
        listLabel: String,
        helpText: String,
        inline: Boolean,
        multiple: Boolean,
        infoView: Boolean,
        disabled: Boolean,
        required: Boolean,
        readonly: Boolean,
        preload: Boolean,
        preview: Boolean,
        filter: Object,
        placeholder: String,
        excludedId: [Array, String, Number],
        errors: [Array, Object, String],
        defaultResettable: Boolean
    },
    data() {
        return {
            target: this.$root.getRoutePath('user.autocomplete'),
            imageBasePath: this.$root.CONSTANTS.IMAGE_PATHS.USER,
            icon: ['fal', 'user'],
        }
    },
    computed: {
        actions() {
            return [
                this.infoView ? { id: 'info', icon: 'info', type: 'user' } : null,
                this.readonly ? null : { id: 'detach', icon: 'times' }
            ].filter(v => !!v)
        }
    },
    methods: {
        parseEntity(user) {
            return parseEntity(user)
        },
        parseSearch(option) {
            return 'ID: ' + option.value + '; ' + option.title + ' ' + option.subTitle
        },
        genderIcon(gender) {
            return gender !== 1 ? gender !== 2 ? 'x' : 'female' : 'male'
        }
    }
}

const parseEntity = function (user) {
    return {value: user.Id, title: user.Name, subTitle: user.Email, imagePath: user.ImagePath, __data: user}
}

export {
    parseEntity
}
</script>