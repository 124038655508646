const privacyTypes = {
    IMPRINT: 1,
    PRIVACY: 2,
    TICKETING: 3,
    TERMS: 4
}

const netdriveTypes = {
    INTERN_WEBDAV: 2,
}

const moduleAccess = {
    SOCIAL: 2,
    DASHBOARD: 3,
    TASK: 4,
    CRM: 5,
    FILE: 6,
    MEDIA: 7,
    MAIL: 8,
    PIM: 9,
    PUBLISH: 10,
    ENTITY: 11,
    SOCIALMEDIA: 12
}

const imagePaths = {
    USER: '/storage/users/',
    GROUP: '/storage/groups/',
    TEMP: '/storage/temp/',
    WATERMARKS: '/storage/watermarks/',
}
const moduleTypes = {
    USER: 1,
    FILE: 2,
    MEDIA: 3,
    TASK: 4,
    NEWSLETTER: 5,
    ENTITY: 6,
    PUBLISHER: 8,
    CRM: 9,
    SYSTEM: 10,
    SOCIAL: 11,
    SOCIALMEDIA: 12
}

const searchPresetTypes = {
    OWN: 0,	// die vom Nutzer bei der Suche gespeicherte Presets
	VIEW: 1,
	EDIT: 2,
	DOWNLOAD: 3,
	SEND: 4,
    DELETE: 11,
	APP: 5,
	QUICK: 8
}

const renderPresetTypes = {
    ORIGINAL: 0,
	ALL: -1,
	FLEX: -2
}

const selection = {
    ADD: 'add',
    ALL: 'addAll',
    REMOVE: 'remove',
    CLEAR: 'removeAll',
    ADDLP: 'addLP',
    REMOVELP: 'removeLP',
    CLEARLP: 'removeAllLP'
}

const GDPRApproval = {
    ACCEPTED: 2,
    REJECTED: 1
}
module.exports = {
    PRIVACY_TYPES: privacyTypes,
    NETDRIVE_TYPES: netdriveTypes,
    MODULE_ACCESS: moduleAccess,
    IMAGE_PATHS: imagePaths,
    SELECTION: selection,
    MODULE_TYPES: moduleTypes,
    SEARCH_PRESET_TYPES: searchPresetTypes,
    RENDER_PRESET_TYPES: renderPresetTypes,
    GDPR_APPROVAL: GDPRApproval
}