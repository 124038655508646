<template>
    <ButtonStd :primary="primary" adaptive
        :weight="weight"
        class="btn-onblue"
        @click="handleClick"
        v-bind="$root.addTestLabel('modal-'+type)"
    >
        <IconSolid :icon="icon" />
        <span>{{ title || $root.l10n(type) }}</span>
    </ButtonStd>
</template>

<script>
import IconSolid from '../../utility/IconSolid.vue'
import ButtonStd from '../../forms/ButtonStd.vue'

export default {
    name: "ModalButton",
    components: {
        IconSolid,
        ButtonStd
    },
    props: {
        type: String,
        title: String,
        primary: Boolean,
        weight: {type: Boolean, default: true},
        icon: String
    },
    methods: {
        handleClick() {
            this.$emit('clicked',this.type)
        }
    }
}
</script>