<template>
    <div class="progress-bar-page-top" v-if="shown">
        <div :style="{width: percentage+'%'}"></div>
    </div>
</template>

<script>

export default {
    name: 'LoadingBar',
    components: {  },
    props: {
        percentage: Number
    },
    data() {
        return {
            shown: this.percentage < 100,
            timer: 0
        }
    },
    watch: {
        percentage(to) {
            clearTimeout(this.percentage)
            if (this.percentage === 100) {
                this.timer = setTimeout(() => {
                    this.shown = false
                }, 1500);
            } else this.shown = true
        }
    }
}
</script>