<script>
import {onBeforeUnmount} from 'vue'
import ajax from '../../lib/ajax'

export default function useActivityPolling() {
    let interval, timer, version, route
    const pollInterval = 30e3
    const HTTP_STATUS = {
        UNAVAILABLE: 503,
        UNAUTHORIZED: 401,
        CONFLICT: 409
    }
    const listeners = []
    let confirmCustom = null

    /** @type {(message: string) => Promise<unknown>} */
    const confirmDialog = function (message) {
        return confirmCustom ? confirmCustom(message) : new Promise(resolve => {
            if (confirm(message)) {
                resolve(true)
            } else {
                resolve(false)
            }
        })
    }

    onBeforeUnmount(() => {
        clearTimeout(timer)
        stopPolling()
    })

    /**
     * clear and unset interval
     */
    const stopPolling = function () {
        clearInterval(interval)
        interval = undefined
    }

    /**
     * @returns {function: boolean}
     */
    const terminated = function () {
        const ms = Date.now(), limit = 10e3
        return () => Date.now() > ms + limit || interval === undefined
    }

    /**
     * @param {string} endpoint
     * @param {string} engineVersion
     */
    const init = function (endpoint, engineVersion) {
        route = endpoint
        version = engineVersion
        launch()
    }

    /**
     */
    const launch = function () {
        if (interval !== undefined) stopPolling()
        interval = setInterval(() => request(terminated()), pollInterval)
    }

    /**
     * @param {function: boolean} cancel
     * @returns {Promise<Object>}
     */
    const request = function (cancel = () => false) {
        if (!route) return Promise.refect('polling.endpoint is undefined')

        const data = {
            tstamp: Date.now() / 1e3 - 1,
            hash: version,
            //,"active" : __Engine.ActiveModule
        }

        listeners.forEach(listener => {
            Object.assign(data, (typeof listener.data === 'function' ? listener.data() : listener.data || {}))
        })

        return ajax.json(route, {data, cancel}).then(({response}) => {
            success(response)
        }).catch(failure)
    }

    /**
     * @param {any} response
     */
    const success = function (response) {
        //console.log('polling.success', response)
        listeners.forEach(listener => {
            listener.callback(response)
        })
    }

    /**
     * @param {{code: int, message: string, response: any}} data
     */
    const failure = function (data) {
        console.debug('polling.failure', data)
        stopPolling()
        if (data.code === HTTP_STATUS.UNAVAILABLE) {
            return serverUnavailable(data)
        } else if (data.code === HTTP_STATUS.CONFLICT) {
            return versionConflict(data)
        } else if (data.code === HTTP_STATUS.UNAUTHORIZED) {
            return sessionExpired()
        } //else return console.warn(data)
    }

    /**
     * @param {{message: string}} data
     */
    const serverUnavailable = async function (data) {
        if (await confirmDialog(data.message + ' Reload page?')) {
            window.location.reload()
        } else launch()
    }

    /**
     */
    const sessionExpired = function () {
        window.$root.logout('auto')
    }

    /**
     */
    const versionConflict = async function (data) {
        if (await confirmDialog(data.response)) {
            window.location.reload()
        } else {
            timer = setTimeout(launch, 60000)//1min
        }
    }

    /**
     * @param {function} callback
     * @param {object|function} data
     */
    const addListener = function (callback, data = undefined) {
        if (typeof callback !== 'function') {
            console.warn('callback is not a function, cannot be used as listener')
        } else {
            listeners.push({callback, data: data})
        }
    }

    /**
     * @param {listeners} callback
     */
    const removeListener = function (callback) {
        for (let i = listeners.length - 1; i >= 0; i--) {
            if (listeners[i].callback === callback) {
                listeners.splice(i, 1)
            }
        }
    }
    /**
     * @param {function(message: string): Promise.<boolean>} callback
     */
    const confirmBehavior = function (callback) {
        confirmCustom = callback
    }

    return {
        init: (endpoint, version) => init(endpoint, version),
        stop: () => stopPolling(),
        poll: () => request(),

        addListener,
        removeListener,
        confirmBehavior
    }
}
</script>