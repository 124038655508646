<template>
    <div class="spinner-wrapper">
        <div class="spinner-inner">
            <div v-if="progress" class="spinner-percentage" >{{ percentage.toFixed(1) }} %</div>
            <IconDuotone icon="spinner-third" :spin="true" />
            <div v-if="progress" class="spinner-info">
                <p>
                    {{ $root.l10n('loading') }}
                </p>
                <p v-if="details">
                    {{ details }}
                </p>
            </div>
            <div v-else-if="details" class="spinner-info">
                <p>{{ details }} </p>
            </div>
        </div>
    </div>
</template>

<script>
import IconDuotone from '../utility/IconDuotone.vue'

export default {
    name: "LoadingSpinner",
    components: {IconDuotone},
    props: {
        progress: Number,
        details: String,
        initial: {type: Number, default: 0},
        animationSpeed: {type: Number, default: 3000}
    },
    setup() {
        return {
            animate: null
        }
    },
    data() {
        return {
            percentage: this.initial
        }
    },
    methods: {
        initAnimatedProgress() {
            //console.log('initAnimatedProgress', this.progress, !!this.animate)
            if (!this.animate) {
                this.animate = {
                    terminated: false,
                    incrementing: animatedIncrement({
                        duration: this.animationSpeed,
                        value: 0, limit: 0,
                        progress: (p) => this.percentage = p,
                        success: () => setTimeout(() => this.$emit('completed'), 50), //note: let component show last value
                        cancel: () => this.terminated,
                    }),
                    stop () { this.terminated = true }
                }
            }
            this.animate.terminated = false
            this.animate.incrementing.start(this.percentage || 0, this.progress, this.animationSpeed)
        }
    },
    watch: {
        progress(to, from) {
            if (isNaN(to)) {
                this.percentage = this.initial
                this.animate.terminated = true
                this.animate?.incrementing?.stop?.()
            } else if (isNaN(from)) {
                this.percentage = this.initial
                this.initAnimatedProgress()
            } else if (to === 100 && this.percentage >= 0) {
                this.animate?.incrementing?.start(this.percentage, to, 500)
            } else this.animate?.incrementing?.start(this.percentage, to)
        }
    },
    mounted() {
        if (this.progress !== undefined) this.initAnimatedProgress()
    },
    beforeDestroy() {
        this.animate?.incrementing?.stop()
    }
}




/**
 * @author kps
 * @created 2022-08-03
 * @param {object|number} args[0]
 * @param {number} args[1] start value
 * @param {number} args[2] end value
 * @param {function} args[3] callback
 * @return {{start: function, stop: function}}
 * @example
 *         setTimeout(() => {
 *             const ani = animatedIncrement({
 *                 duration: 3000,
 *                 value: 0, limit: 5,
 *                 progress: (p) => this.value = p.toFixed(2),
 *                 success: () => {this.value = '100% Done'}
 *             })
 *
 *             let tst = 4000
 *             setTimeout(() => ani.start(undefined, 6), tst)
 *             setTimeout(() => ani.start(undefined, 12), tst+= 3000)
 *             setTimeout(() => ani.start(undefined, 90), tst+= 2000)
 *             setTimeout(() => ani.start(undefined, 100, 1000), tst+= 2500)
 *         }, 1000);
 */
function animatedIncrement(...args) {

    const validDuration = function (dur) {
        return Math.max(0, parseInt(dur, 10))
    }

    if (typeof args[0] !== 'object') {
        args = {
            duration: validDuration(args[0]),
            value: parseFloat(args[1]),
            limit: parseFloat(args[2]),
            success: typeof args[3] === 'function' ? args[3] : function () {},
        }
    } else args = args[0]


    const object = {
        _interval: null,
        _crtime: Date.now(),
        _state: null, // 0 init, 1 running, 2 completed, 3 terminated
        _args: {
            duration: validDuration(args.duration),
            value: parseFloat(args.value),
            limit: parseFloat(args.limit),
            progress: typeof args.progress === 'function' ? args.progress : function (progress) {},
            success: typeof args.success === 'function' ? args.success : function () {},
            cancel: typeof args.cancel === 'function' ? args.cancel : function () { console.log('default cancel'); return false },
        },

        /**
         * @param {number?} start - initial value
         * @param {number?} end
         * @param {number?} duration
         */
        start: function (start, end, duration) {
            clearInterval(this._interval)

            const that = this._args
            const speed = 100

            this._state = 1
            that.value = start === undefined ? that.value : start
            that.limit = end === undefined ? that.limit : end
            that.duration = duration === undefined ? that.duration : validDuration(duration)

            if (isNaN(that.value) || isNaN(that.limit)) {
                throw Error('Animated: wrong data (' + that.value + ', ' + that.limit + ')')
            }

            duration = that.duration
            let delta = that.limit - that.value
            let inc = duration !== 0 ? delta / duration * speed : delta;
            if (inc === 0) {
                that.success(); return ;
            }

            //console.log(that.value, that.limit, duration, speed, inc)
            this._interval = setInterval(() => {
                if (that.cancel()) return this.stop()

                that.value = inc > 0 ?
                    Math.min(that.limit, that.value + inc) :
                    Math.max(that.limit, that.value + inc)

                that.progress(that.value)
                //console.log('step:', that.value.toFixed(2), inc, that.cancel())
                if (that.value === that.limit) {
                    //console.log('animated', Date.now() - this._crtime)
                    clearTimeout(this._interval)
                    this._state = 2
                    that.success()
                }
            }, speed);
        },
        /** */
        stop: function () {
            clearInterval(this._interval)
            this._state = 3
        },
        /**
         * @param {function} callback
         */
        success: function(callback) {
            this._args.success = callback
        }
    }

    if (args.limit !== args.value) object.start()
    return object
}

</script>