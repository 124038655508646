<template>
    <div class="form-element">
        <div :class="wrapClass">
            <slot />
            <div class="console" v-if="!!$slots.additional"><slot name="additional" /></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ElementGroup",
    props: {
        info: Boolean,
        transparent: Boolean,
    },
    computed: {
        wrapClass () {
            return (this.info ? 'info-group' : 'form-group') + (this.transparent ? ' transparent' : '')
        }
    }
}
</script>