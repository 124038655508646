<template>
    <div class="search-word-item">
        <span v-if="uuid" class="search-word-combination">
            <img v-if="uuidLoaded"
                :src="uuid.src"
                :alt="uuid.title"
                width="30"
                v-tooltip="{
                    placement: 'bottom',
                    popperClass: 'image-tooltip',
                    content: uuid.tooltip,
                    html: true
                }"
            />
            <LoadingSpinner v-else />
            <span>{{ uuid.title }}</span>
        </span>
        <span v-else
            v-tooltip="$root.l10n('click_to_edit_search_word')"
              :contenteditable="true"
              @keydown.enter.prevent="enter"
              @blur="enter"
              @paste.prevent="onPaste"
        >{{ modelValue }}</span>
        <IconLight icon="times" @click="remove"/>
    </div>
</template>

<script>
import {inject, ref, watch} from 'vue'
import IconLight from "@/components/utility/IconLight";
import LoadingSpinner from '../../LoadingSpinner'
import {directive as ClickOutside} from "click-outside-vue3";

export default {
    name: "SearchWord",
    components: {
        LoadingSpinner,
        IconLight
    },
    props: {
        modelValue: String
    },
    directives: {
        ClickOutside
    },
    setup(props) {
        const upload = ref(null)
        const uploaded = ref(false)
        watch(
            () => props.modelValue,
            (value) => {
                uploaded.value = false
                if (value?.indexOf('UUID:') === 0) {
                    const getRoute = inject('getRoute', (k) => {})
                    const src = getRoute('system.upload.thumb')?.preparePath(value.substring(5)) || '#'
                    const title = value.substring(5).split('-')[0] + '...'
                    upload.value = {
                        src,
                        title,
                        tooltip: `<div><img src="${src}" style="max-width: 600px; max-height: 400px; margin: 7px auto 0 auto;" alt="upload preview" /></div>`
                    }

                    const img = new Image()
                    img.src = src
                    img.onload = () => uploaded.value = true

                } else upload.value = null
            },
            { immediate: true }
        )
        return {
            uuidLoaded: uploaded,
            uuid: upload,
        }
    },
    methods: {
        remove() {
            this.$emit('remove')
        },
        enter(event) {
            const text = event.target.innerHTML
            event.target.scrollLeft = 0
            if (text === '') {
                this.remove()
            } else if (this.modelValue !== text) {
                this.$emit('update:modelValue', text)
            }
        },
        onPaste (event) {
            const currentText = event.target.innerHTML
            const clipBoard = event.clipboardData || window.clipboardData
            const pastedText = clipBoard.getData('text/plain')
            event.target.innerHTML = currentText + pastedText
        }
    }
}
</script>