<template>
    <div class="info-short d-flex" >
        <div>
            <TextOverflow>{{ title }}</TextOverflow>
            <div class="info-short-subtitle">
                <TextOverflow>{{ subtitle }}</TextOverflow>
                <button
                    v-if="subtitle.startsWith('ID: ')"
                    type="button"
                    class="link-to-clipboard-btn"
                    :class="{ 'transition-class': !copyClicked, 'no-transition-class': copyClicked }"
                    aria-label="copy"
                    @click="copyId(subtitle.substring('ID: '.length))"
                    @mouseenter="hover = true"
                    @mouseleave="hover = false"
                    v-tooltip="copyClicked ? '' : $root.l10n('copy_id')">
                    <IconSolid v-if="hover || copyClicked" :icon="copyClicked ? 'check-circle' : 'copy'"
                        v-tooltip="{content: copyClicked ? $root.l10n('copy_success') : '', shown: true}"/>
                    <IconLight v-else icon="copy"/>
                </button>
            </div>
        </div>
        <template v-if="img">
            <IconLight v-if="icon" :icon="icon" class="img" />
            <img v-else :src="src" class="img" />
        </template>
    </div>
</template>

<script>
import IconLight from '../../utility/IconLight'
import TextOverflow from "../../utility/TextOverflow";
import copy from 'copy-to-clipboard'
import IconSolid from '../../utility/IconSolid'

export default {
    name: 'ModalSidebarInfo',
    components: {TextOverflow, IconLight, IconSolid },
    props: {
        title: String,
        subtitle: String,
        imagePath: Array
    },
    data() {
        return {
            copyClicked: false,
            hover: false
        }
    },
    computed: {
        /**
         * @returns {?Array} */
        img () {
            return (this.imagePath instanceof Array && this.imagePath.length > 1) ? this.imagePath : null
        },
        /**
         * @returns {?string}
         */
        icon () {
            return this.img && this.img[0] === 'icon' ? this.imagePath[1] : null
        },
        /**
         * @returns {?string}
         */
        src () {
            return this.img && this.img[0] !== 'icon' ? this.imagePath[1] : null
        }
    },
    methods: {
        copyId(id) {
            copy(id)
            this.copyClicked = true

            setTimeout(() => {
                this.copyClicked = false
                this.hover = false
            }, 4000)
        }
    }
}
</script>

<style scoped>
.transition-class {
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
    transform: translateX(-100%);
}

.no-transition-class {
    opacity: 1;
    transform: translateX(0);
}

.info-short-subtitle:hover .link-to-clipboard-btn {
    opacity: 1;
    transform: translateX(0);
}
</style>