<template>
    <div class="progress-bar" v-if="shown">
        <div
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            :aria-valuenow="percentage"
            :style="{width: percentage+'%'}"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'ProgressBar',
    props: {
        showing: {
            type: [String,Boolean],
            default: 'auto'
        },
        percentage: {
            type: Number,
            default: 0.00
        }
    },
    computed: {
        shown () {
            return typeof(this.showing) === 'boolean' ? this.showing : (this.percentage > 0 && this.percentage < 100)
        }
    }
}
</script>