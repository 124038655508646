<template>
    <div class="preview-container" :class="previewMode === 'user' ? 'user-preview' : ''" :style="{ backgroundImage: file ? `url(${filepath})` : 'none'}">
        <FontAwesomeIcon v-if="!file && icon"
            :icon="icon"
            class="i"
        />
        <slot v-else-if="!file" />
    </div>
</template>

<script>
import FontAwesomeIcon from '../../../../lib/fontawesome'
export default {
    name: 'ItemPreview',
    components: {FontAwesomeIcon},
    props: {
        file: String,
        path: {
            type: String,
            default: ''
        },
        icon: {
            type: Array,
            default() { return ['fal', 'image']}
        },
        previewMode: String
    },
    computed: {
        filepath () {
            const basePath = process.env.BASE_URL
            const filepath = this.file.indexOf('/') === -1 ? this.path + this.file : this.file
            return filepath.indexOf('assets/') !== 0 ? filepath : basePath + filepath
        }
    }
}
</script>