<template>
    <div id="headerWrapper" class="d-flex flex-row">
        <div id="logo" class="logo-logged" @click.stop="gotoMain"></div>
        <SearchBar/>
        <Toolbar />
    </div>
</template>

<script>
import SearchBar from "./search/SearchBar.vue";
import Toolbar from "./Toolbar.vue";

export default {
    name: "Header",
    components: { SearchBar, Toolbar },
    props: {
        home: String,
    },
    methods: {
        gotoMain() {
            const route = this.$root.getRoute(this.home || 'home')
            this.$root.goToRoute(route)
        }
    }
}
</script>
