class Actions {

    /**
     * @param {Object} data
     */
    constructor(data) {
        this.MARK_MEDIA_OBJECT = data.MARK_MEDIA_OBJECT || 0
        this.MARK_FILE_OBJECT = data.MARK_FILE_OBJECT || 0
        this.MARK_CRM_OBJECT = data.MARK_CRM_OBJECT || 0
        this.MARK_ENTITY_OBJECT = data.MARK_ENTITY_OBJECT || 0
        this.MARK_MEDIA_LIST_PRESET = data.MARK_MEDIA_LIST_PRESET || 0
    }
}

module.exports = {
    Actions
}