<template>
    <MainWrapper>
        <ErrorPage :error="error" :debug="debug" />
    </MainWrapper>
</template>

<script>
import MainWrapper from '../layout/content/MainWrapper'
import ErrorPage, {ConstructionError, NotFoundError} from '../layout/ErrorPage'

export default {
    name: "ErrorController",
    components: { MainWrapper, ErrorPage },
    props: {
        action: {
            type: String,
            required: true
        },
        args: Object
    },
    computed: {
        /** @returns {Error} */
        error () {
            if (this.message instanceof Error) {
                return this.message
            } else if (this.action === ConstructionError.name) {
                return new ConstructionError(this.message)
            } else if (this.action === 'notFound') {
                return new NotFoundError(this.message)
            } else return new Error(this.message)
        },
        /** @returns {Error|String} */
        message() {
            return this.args?.message || this.$root.l10n('unknown_error')
        },
        /** @returns {any} */
        debug() {
            return this.args?.debug || '';
        }
    },
    mounted() {
        this.$root.setLoadingPercentage(100)
    }
}
</script>