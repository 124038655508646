const Collection = require('./collection')

class Setting {
    /**
     * @param {Object} data
     */
    constructor (data) {
        this.id = null
        this.value = null

        if (data && Object.keys(data).length > 0) this.set(data)
    }

    /**
     * @param {Object} data
     */
    set(data) {
        this.id = data.Name || data.name || null
        this.value = data.Value || data.value || ''
    }
}

class SettingCollection extends Collection {
    /**
     * @param {Object} element
     * @return {Setting}
     */
    castObject(element) {
        return (element instanceof Setting) ? element : new Setting(element)
    }
}

/**
 * @param {SettingCollection} settings
 * @param {Setting} el
 */
const associateSetting = function (settings, el) {
    if (el.id in settings) delete settings[el.id]
    Object.defineProperty(settings, el.id, {
        get() { return adjustValue(el.value) },
        set(v) { el.value = v },
        enumerable: false
    })
}

const adjustValue = function (value) {
    if (value === 'false') return false
    else if (!isNaN(value) && typeof value === 'string')
        return value * 1
    else return value
}

module.exports = {
    Setting,
    SettingCollection,
    associateSetting
}