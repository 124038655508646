class LocalStorage {
    /**
     *
     */
    constructor () {
        this.storage = typeof localStorage !== 'undefined' ? localStorage :
            (typeof window !== 'undefined' ? window.localStorage : null)
        if(!this.storage) {
            console.warn("localStorage does not supported!")
            this.storage = new Storage()
        }
    }

    set length (val) { }
    get length () { return this.storage.length }

    /**
	 * get value by key
     * @param {string} key
	 * @returns {?string|?object}
     */
    get (key) {
        try {
            return JSON.parse(this.storage.getItem(key))
        } catch(e) {
            return this.storage.getItem(key)
        }
	}

    /**
	 * set value by key
     * @param {string} key
     * @param {any} val
     * @returns {?string|?object}
     */
    set (key, val) {
		this.storage.setItem(key, JSON.stringify(val))
        return this.get(key)
	}

    /**
	 * remove value by key
     * @param {string} key
     * @returns {boolean}
     */
	remove (key) {
        if (key in this.storage) {
            this.storage.removeItem(key)
            return true
		} else return false
    }

    /**
	 * clear whole storage
     * @returns {number} total removed storage items
     */
    clear () {
        const len = this.storage.length
        this.storage.clear()
        return len
	}

    /** window.localStorage fallback */
    setItem(key, value) { this.set(key, value) }
    getItem(key) { return this.get(key) }
    removeItem(key) {this.remove(key) }

    /** */
    getContext(context) {
        return new ContextStorage(this, context)
    }
}

/**
 *
 */
class ContextStorage {
    /**
     * @param {LocalStorage|ContextStorage|Storage} storage
     * @param {string} context
     */
    constructor(storage, context) {
        this.context = context
        this.storage = storage
        this.data = storage.get(context) || {}
    }

    set length (val) { }
    get length () { return Object.keys(this.data).length }

    /**
     * get value by key
     * @param {string} key
     * @returns {?string|?object}
     */
    get (key) {
        return this.data[key]
    }

    /**
     * set value by key
     * @param {string} key
     * @param {any} val
     * @returns {?string|?object}
     */
    set (key, val) {
        this.data[key] = val
        this.storage.set(this.context, this.data)
        return this.get(key)
    }

    /**
     * remove value by key
     * @param {string} key
     * @returns {boolean}
     */
    remove (key) {
        if (key in this.data) {
            delete this.data[key]
            this.storage.set(this.context, this.data)
            return true
        } else return false
    }

    /**
     * clear whole storage
     * @returns {number} total removed storage items
     */
    clear () {
        const len = this.length
        this.data = {}
        this.storage.set(this.context, this.data)
        return len
    }

    /** window.localStorage fallback */
    setItem(key, value) { this.set(key, value) }
    getItem(key) { return this.get(key) }
    removeItem(key) {this.remove(key) }

    /** */
    getContext(context) {
        return new ContextStorage(this, context)
    }
}

/**
 * fallback if window.localStorage not exists
 * for example by using under node (tests)
 */
class Storage {
    constructor (storage) {
        if (storage) {
            for (const prop in storage)
                this[prop] = storage[prop]
        }
    }

    set length (val) { }
    get length () { return Object.keys(this).length }

    setItem(key, value) {
        this[key] = String(value)
    }
    getItem(key) {
        return typeof this[key] !== 'undefined' ? this[key] : null
    }
    removeItem(key) {
        return typeof this[key] !== 'undefined' ? delete this[key] : false
    }
    clear() {
        for (let k in this) delete this[k]
    }

    /** */
    getContext(context) {
        return new ContextStorage(this, context)
    }
}

module.exports = new LocalStorage()