<template>
    <div
        id="sidebarWrapper"
        class="d-flex flex-row"
        :class="toggleNav ? 'toggle' : null"
    >
        <div
            class="nav-toggle"
            :class="toggleNav ? 'toggle' : null"
            @click="toggleNav = !toggleNav"
        >
            <div></div>
        </div>
        <Navi :items="naviItems" :currentPath="currentPath" />
        <div v-if="sidebarExists"
            id="sidebar"
            :class="transition ? 'transition' : null"
            class="bg-grey-light"
            ref="sidebarContainer"
            :style="{width: showSidebar ? (sidebarWidth) + 'px' : '30px'}"
        >
            <component :is="sidebar" v-bind="sidebarArgs" v-show="showSidebar"/>
            <div class="btn-collapse-wrapper" @click="toggleSidebar" :class="!showSidebar ? 'hidden' : null">
                <div
                    class="btn-collapse"
                    v-tooltip="showSidebar ?  $root.l10n('hide_sidebar') : $root.l10n('show_sidebar')"
                >
                    <IconLight icon="chevron-double-left" />
                </div>
            </div>
            <div v-if="showSidebar" class="handle" @mousedown="resizeStart">
            </div>
        </div>
    </div>
</template>

<script>
import {shallowRef} from "vue"
import Navi from "./navi/Navi.vue";
import EventBus from '../../../lib/helpers/EventBus'
import IconLight from "@/components/utility/IconLight"

export default {
    name: 'Sidebar',
    components: {
        IconLight,
        Navi
    },
    props: {
        naviItems: {
            type: Array,
            default() { return []; }
        }
    },
    data() {
        return {
            toggleNav: false,
            showSidebar: true,
            resizableHeight: 1,
            sidebarWidth: 300,
            transition: true,
            sidebar: shallowRef(null),
            sidebarArgs: shallowRef(null),
            startX: 0,
            startWidth: 0
        }
    },
    computed: {
        sidebarExists() {
            return !!this.sidebar
        },
        currentPath: function() {
            return this.$root.currentRoute.path;
        }
    },
    methods: {
        emitEventResizeSidebar() {
            EventBus.$emit('sidebar.resize');
        },
        toggleSidebar() {
            this.showSidebar = !this.showSidebar
            localStorage.setItem('showSidebar', JSON.stringify(this.showSidebar));
            !this.showSidebar ? this.setSidebarWidth(0) : this.setSidebarWidth(parseInt(localStorage.getItem('sidebarWidth')) || 300)
        },
        setSidebarWidth(width) {
            if (this.sidebarWidth === width) return
            this.sidebarWidth = Math.min(Math.max(width, 200), 400)
        },
        /**
         * @param {Promise} vc
         * @param {?object} args
         */
        setSidebarComponent(vc, args) {
            if (vc) {
                this.sidebar = vc
                this.sidebarArgs = args || {}
            } else this.sidebar = this.sidebarArgs = null
        },
        resizeStart(evt) {
            this.transition = false
            this.startX = evt.clientX
            this.startWidth = this.sidebarWidth
            document.body.style.userSelect = 'none'
            document.addEventListener('mousemove', this.resize, false)
            document.addEventListener('mouseup', this.resizeEnd, false)
        },
        resize(evt) {
            const newWidth = this.startWidth + evt.clientX - this.startX
            this.sidebarWidth = Math.min(Math.max(newWidth, 200), 400)
        },
        resizeEnd() {
            this.transition = true
            localStorage.setItem('sidebarWidth', this.sidebarWidth)
            document.body.style.userSelect = 'auto'
            document.removeEventListener('mousemove', this.resize, false);
            document.removeEventListener('mouseup', this.resizeEnd, false);
        }
    },
    mounted() {
        this.resizableHeight = this.$refs.sidebarContainer?.offsetHeight || 1
        this.showSidebar = localStorage.getItem('showSidebar') ? JSON.parse(localStorage.getItem('showSidebar')) : true
        this.sidebarWidth = this.showSidebar ? localStorage.getItem('sidebarWidth') ? parseInt(localStorage.getItem('sidebarWidth')) : 300 : 0
        EventBus.$on('sidebar.set', this.setSidebarComponent)
    },
    beforeUnmount() {
        EventBus.$off('sidebar.set', this.setSidebarComponent)
    },
    watch: {
        showSidebar(){
            this.emitEventResizeSidebar()
        },
        sidebarWidth(){
            this.emitEventResizeSidebar()
        }
    }
};
</script>
