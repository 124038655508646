<template>
    <div>
        <img v-show="successfully" ref="canvas" :class="canvasClass || 'qq-thumbnail-selector'" />
        <slot v-if="failed" name="unavailable" >
            <IconLight icon="file" />
        </slot>
        <slot v-else-if="processing" name="waiting">
            <LoadingSpinner />
        </slot>
    </div>
</template>

<script>
import IconLight from '../IconLight'
import LoadingSpinner from '../../layout/LoadingSpinner'

export default {
    name: "UploaderThumb",
    components: {
        IconLight, LoadingSpinner
    },
    props: {
        fromServer: {
            type: Boolean
        },
        id: {
            type: Number,
            required: true
        },
        size: {
            type: Number,
            default: 120
        },
        src: null,
        canvasClass: String,
        uploader: {
            type: Object,//FineUploader
            required: true
        }
    },
    data () {
        return {
            complete: 0,
        }
    },
    computed: {
        processing () {
            return this.complete === 0
        },
        successfully () {
            return this.complete === 1
        },
        failed () {
            return this.complete === -1
        }
    },
    watch: {
        src() {
            if (this.src) {
                this.complete = 1
                this.$refs.canvas.src = this.src
                this.$forceUpdate()
            }
        }
    },
    mounted () {
        this.uploader.methods.drawThumbnail(
            this.id,
            this.$refs.canvas,
            this.size,
            //?this.fromServer
        ).then(
            (img) => {
                this.$emit('update:modelValue', img.src)
                this.complete = 1
            },
            () => this.complete = -1
        )
    }
}
</script>