
class Collection extends Array {
    /**
     * @param  {...Object|Collection|Array} args
     */
    constructor (...args) {
        super()
        if (args.length === 1 && (args[0] instanceof Array)) {
            this.push(...args[0])
        } else if (args.length > 1 || typeof args[0] === "object") {
            this.push(...args)
        }
    }

    /**
     * returns count of elements in collection
     * @notice fallback be-style
     * @returns {Number}
     */
    getCount () {
        return this.length
    }

    /**
     * aliase to push, fallback be-style
     * @param {any} elem
     */
    add (...elem) {
        this.push(...elem)
    }

    /**
     * @param  {...Object} elem
     */
    push(...elem) {
        for (let i in elem) {
            let casted = this.castObject(elem[i])
            if (casted && !this.getById(casted.id))
                super.push(casted)
        }
    }

    /**
     * cast raw element to specific object, returns null if failed
     * @param {Object} elem
     * @returns {Object}
     */
    castObject(elem) {
        return elem
    }

    /**
     * add elements to collection
     * @param {Array} data
     */
    addCollection (data) {
        if (data instanceof Array) this.push(...data)
        else throw new SyntaxError('invalid argument type, expected Array instance')
    }

    /**
     * returns an element of collection by index
     * @param {Number} index
     * @returns {any}
     */
    getByIndex (index) {
        return typeof this[index] !== "undefined" ?
            this[index] : null
    }

    /**
     * @param {String|Number} id
     * @returns {any}
     */
    getById (id) {
       for (let j in this) {
            if (this[j].id === id)
                return this[j]
        }
        return null
    }
}

module.exports = Collection