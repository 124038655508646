/**
 * Addition to Attribute
 * describe all possible attribute types
 */
const Types = {
    TEXTFIELD           : "SYS_TEXT",
    NUM_TEXTFIELD       : "SYS_NUM",
    SELECTBOX           : "SYS_SELECT",
    MULTIPLE_SELECTBOX  : "SYS_MSELECT",
    CHECKBOX            : "SYS_CHECKBOX",
    RADIO               : "SYS_RADIO",
    TEXTAREA            : "SYS_TEXTAREA",
    HTML_TEXT           : "SYS_RTE",
    DATE                : "SYS_DATE",
    DATE_TIME           : "SYS_DATETIME",
    TIME                : "SYS_TIME",
    LINK                : "SYS_LINK",
    MAIL                : "SYS_MAIL",
    USER_LINK           : "SYS_ULINK",
    MULTIPLE_USER_LINK  : "SYS_MULINK",
    OBJECT_ATTACH       : "SYS_OBJ_ATTACH",
    //DATA_UPLOAD         : "SYS_UPLOAD", wasn't built even in cb

    META_EXTENSION           : "PSEUDO_META_1",
    META_MD5HASH             : "PSEUDO_META_2",
    META_SIZE                : "PSEUDO_META_4",

    META_WIDTH               : "PSEUDO_META_5",
    META_HEIGHT              : "PSEUDO_META_6",
    META_RESOLUTION_X        : "PSEUDO_META_7",
    META_RESOLUTION_Y        : "PSEUDO_META_8",

    META_ICCPROFILE           : "PSEUDO_META_9",
    META_COLORSPACE           : "PSEUDO_META_10",
    META_ALPHA                : "PSEUDO_META_11",
    META_DOMINANT_COLOR       : "PSEUDO_META_25",

    META_BITS                 : "PSEUDO_META_12",
    META_MIME                 : "PSEUDO_META_13",
    META_PAGE_COUNT           : "PSEUDO_META_14",

    META_VIDEO_CODEC          : "PSEUDO_META_15",
    META_AUDIO_CODEC          : "PSEUDO_META_16",

    META_VIDEO_BITRATE        : "PSEUDO_META_17",
    META_AUDIO_BITRATE        : "PSEUDO_META_18",

    META_DENSITY              : "PSEUDO_META_19",
    META_ORIENTATION          : "PSEUDO_META_20",

    META_VIDEO_FRAMERATE      : "PSEUDO_META_21",
    META_VIDEO_DURATION       : "PSEUDO_META_22",
    META_VIDEO_OVERALLBITRATE : "PSEUDO_META_23",


    PSEUDO_OBJECT_ID         : "PSEUDO_OBJECT_ID",
    PSEUDO_FILENAME          : "PSEUDO_FILENAME",
    PSEUDO_PREVIEW           : "PSEUDO_PREVIEW",
    PSEUDO_EBOOK             : "PSEUDO_EBOOK",
    PSEUDO_CREATE_DATE       : "PSEUDO_CREATE_DATE",
    PSEUDO_EDIT_DATE         : "PSEUDO_EDIT_DATE",
    PSEUDO_CREATOR           : "PSEUDO_CREATOR",
    PSEUDO_CATEGORY          : "PSEUDO_CATEGORY",
    PSEUDO_GROUP             : "PSEUDO_CREATOR_GROUP",
    PSEUDO_ROLE              : "PSEUDO_ROLE",
    PSEUDO_VARIANT           : "PSEUDO_VARIANT",
    PSEUDO_MASTER            : "PSEUDO_MASTER",
    PSEUDO_ARCHIVE           : "PSEUDO_ARCHIVE",
    PSEUDO_GDPR_USERS        : "PSEUDO_GDPR_USERS",
    PSEUDO_DOWNLOAD_COUNT    : "PSEUDO_DOWNLOAD_COUNT",
    PSEUDO_CONTENT           : "PSEUDO_CONTENT",
    PSEUDO_PERMALINK         : "PSEUDO_PERMALINK",
    PSEUDO_SNIPPET           : "PSEUDO_SNIPPET",
    PSEUDO_TRACKS            : "PSEUDO_TRACKS",
    PSEUDO_WATERMARK         : "PSEUDO_WATERMARK"
}

const MetaDecimalCast = [
    Types.META_SIZE,
    Types.META_WIDTH,
    Types.META_HEIGHT,
    Types.META_RESOLUTION_X,
    Types.META_RESOLUTION_Y,
    Types.META_DENSITY,
    Types.META_BITS,
    Types.TYPE_ATTACH_DOWNLOAD_COUNT
]

const getChoosableTypes = () => { return [ Types.RADIO, Types.CHECKBOX, Types.SELECTBOX, Types.MULTIPLE_SELECTBOX ] }
const isChoosable = (type) => { return getChoosableTypes().indexOf( type ) !== -1 }

const isPseudo = (type) => { return type.indexOf('PSEUDO_') === 0 }
const isMeta = (type) => { return type.indexOf('PSEUDO_META_') === 0 }
const isMetaNumeric = (type) => { return MetaDecimalCast.indexOf(type) > -1 }
const isCategory = (type) => { return type.indexOf('PSEUDO_CATEGORY') === 0 ? type.substring(16) || true : false }
const isModuleLink = (type) => { return isSingleModuleLink(type) || isMultipleModuleLink(type) }

const isSingleModuleLink = (type) => { return type.indexOf('MOD_') !== 0 ? 0 : type.substring(4) }
const isMultipleModuleLink = (type) => { return type.indexOf('MMOD_') !== 0 ? 0 : type.substring(5) }

/*
const getMetaKey = (type) => { return isMeta(type) ? type.substr(12) : 0 }
const getTypeOfPseudo = (id) => {
    switch( id ) {
        case Types.PSEUDO_OBJECT_ID: return Types.NUM_TEXTFIELD
        case Types.PSEUDO_FILENAME:
        default: return Types.TEXTFIELD

        case Types.PSEUDO_CREATE_DATE:
        case Types.PSEUDO_EDIT_DATE: return Types.DATE_TIME

        case Types.PSEUDO_CREATOR: return Types.USER_LINK
        //????case Types.PSEUDO_CREATOR:
        //????PSEUDO_PREVIEW
        //????PSEUDO_CATEGORY
        //????PSEUDO_ROLE
        //????PSEUDO_VARIANT
        //????PSEUDO_MASTER
        //????PSEUDO_ARCHIVE
        //????PSEUDO_DOWNLOAD_COUNT
    }
}
*/
module.exports = {
    getChoosableTypes,
    isChoosable,
    isPseudo,
    isMeta,
    isMetaNumeric,
    isModuleLink,
    isMultipleModuleLink,
    isCategory,
    TYPE: Types
}
