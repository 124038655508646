<template>
    <span class="text-overflow-element"
        v-tooltip="{
          content: overflow ? this.text : '',
        }"
    ><slot /></span>
</template>

<script>
export default {
    name: 'TextOverflow',
    data () {
        return {
            overflow: false,
            text: ''
        }
    },
    methods: {
        handler() {
            let elementWidth = this.$el.clientWidth
            this.overflow = this.$el.scrollWidth > elementWidth
            this.text = this.$el.textContent
        }
    },
    mounted () {
        this.$el.addEventListener('mouseover', this.handler)
    },
    beforeUnmount () {
        this.$el.removeEventListener('mouseover', this.handler)
    }
}
</script>