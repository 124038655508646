function pad_left(s) {
    return (s < 10) ? '0' + s : s
}
let updateFaviconUnique = ''
const locale = navigator.languages && navigator.languages.length
    ? navigator.languages[0] : navigator.language

module.exports = {
    /**
     * @param {number} bytes
     * @param {boolean} si
     * @param {number} dp
     * @returns {string}
     * @source https://stackoverflow.com/a/14919494/3651888
     */
    humanFileSize: function humanFileSize(bytes, si = false, dp = 1) {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si
            ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10**dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

        // return bytes.toFixed(dp) + ' ' + units[u];
        return bytes.toLocaleString(locale, {
            minimumFractionDigits: dp,
            maximumFractionDigits: dp
        }) + ' ' + units[u];
    },
    /**
     * @param {number} length = 10
     * @returns {string}
     */
    generateUID: function (length) {
        length = Math.max(length || 10, 1)
        let result = ''
        do {
            result += (Math.random() + 1).toString(36).substr(2)
        } while (result.length < length)
        return result.substr(0, length)
    },
    /**
     * @param {string} format
     * @returns {string}
     */
    adaptDateFormat : function adaptedFormat(format) {
        return format
            .replace(/(%Y|Y)/g, 'YYYY')	 //full year
            .replace(/(%y|y)/g, 'YY') 	 //short year

            //.replace(/(%b|(?<!%)M)\.?/g, 'MMM')//month as short text #FF failed
            .replace(/(%b|%?M)(\.)?/g, function(matched, original, point, index){
                return (matched === '%M' ? original : 'MMM' + (point||''));})
            .replace(/(%B|F)/g, 'MMMM')  //month as text
            .replace(/(%m|m)/g, 'MM') 	 //month

            .replace(/(%a|D)\.?/g, 'ddd')//day of month as short text
            .replace(/(%A|l)/g, 'dddd')	 //day of month as text
            //.replace(/(%d|(?<!d)d(?!d))/g, 'DD') 	 //day of month #FF failed
            .replace(/(%d|d?d(?!d))/g, function(matched, original, index){
                return (matched === 'dd' ? original : 'DD');})
            .replace(/(%e|j)/g, 'D')	 //day of month as decimal

            .replace(/(%H|H)\b/g, 'HH') //hour
            .replace(/(%M|i)\b/g, 'mm') //minutes
            .replace(/(%S|s)\b/g, 'ss')	//seconds
    },
    /**
     * @param {Date} date
     * @returns {String}
     */
    formatDateToDateTime: function formatDateToDateTime(date) {
        date = date ? date instanceof Date ? date : new Date(date) : null
        if (isNaN(date) || date === null) return ''

        let month = pad_left(date.getMonth() + 1),
            day = pad_left(date.getDate()),
            year = '' + date.getFullYear(),
            hour = pad_left(date.getHours()),
            min = pad_left(date.getMinutes()),
            sec = pad_left(date.getSeconds())

        return [year, month, day].join('-') + ' ' + [hour, min, sec].join(':');
    },
    /**
     * @param {String} username
     * @returns {Boolean}
     */
    validateUsername : function(username) {
        let re = /^[a-zA-Z0-9@_.-]{2,200}$/;
        return re.test(username);
    },
    /**
     * @param {String} email
     * @returns {Boolean}
     */
    validateEmail : function validatedEmail(email) {
        let re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return re.test(email);
    },
    /**
     * @param {string} value
     * @returns {boolean}
     */
    validateURL(value) {
        return /^(?:mailto:.+@.+|tel:[+0-9]+|http(s)?:\/\/.+)$/g.test(value)
    },
    /**
     * @param {string} url
     * @returns {?WindowProxy}
     */
    openLink(url) {
        if (this.validateURL(url)) {
            return window.open(url, '_blank')
        } else {
            console.warn('unable to follow invalid url: ' + url)
            return null
        }
    },
    /**
     * @param filepath
     * @return {?{dir: string, filepath: string, filename: string, basename: string, ext: string, extx: string}}
     */
    parseFilePath: function (filepath) {
        const m = String(filepath).match(/^(.*\/)?(.*?)(\.([^.]{1,5}))?$/)
        return m ? {
            dir: m[1] || '',
            filepath: m[0],
            filename: (m[2] || '') + (m[3] || ''),
            basename: m[2] || '',
            ext: m[4] || '',
            extx: m[3] || ''
        } : null
    },
    /**
     * converts pixel to cm
     * @param {number} px
     * @param {number} dpi
     * @return {number}
     */
    pixel2cm: function (px, dpi) {
        const cmPerInch = 2.54
        if (!dpi) console.error('dpi cannot be empty; (px, dpi) => ', px, dpi);
        return dpi ? ((px / dpi) * cmPerInch).toFixed(2) * 1 : 0;
    },
    /**
     * recursive merges objects into target
     * @param {Object} target
     * @param {Object[]} objects
     * @returns {Object}
     */
    objectAssignDeep: function(target, ...objects) {
        const merge = function (t, o){
            for (const k in o) {
                if (!Object.prototype.hasOwnProperty.call(o, k)) continue
                if (o[k] === undefined || o[k] === null) {
                    if (k in t) delete t[k]
                } else if (o[k] instanceof Array) {
                    //?!clone if o[k] is an array of objects?!
                    if (t[k] instanceof Array) {
                        t[k] = t[k].concat(o[k].filter(ok => !t[k].includes(ok)))
                    } else t[k] = [].concat(o[k])
                } else if (typeof o[k] === 'object') {
                    if (typeof t[k] !== 'object' || t[k] === null) {
                        t[k] = Object.assign({}, o[k])
                    } else {
                        merge(t[k], o[k])
                        if (Object.keys(t[k]).length === 0) {
                            delete t[k]
                        }
                    }
                } else t[k] = o[k]
            }
        }

        if (typeof target === 'object') {
            objects.forEach(object => {
                if (typeof object === 'object') merge(target, object)
            })
        }
        return target
    },
    /**
     * @param {string} query
     * @returns {object}
     */
    decodeQueryString: function decodeQueryString(query) {
        if (query.charAt(0) === '?') query = decodeURIComponent(query.substring(1))
        const pieces = query ? query.split("&") : []
        const decoded = {}
        for (const piece of pieces) {
            let [key, value] = piece.split("=")
            if (!key) continue
            key = key.split('[').map((s, ix) => ix > 0 ? s.substring(0, s.length - 1) : s)
            //console.log('decodeURIComponent', value)
            try {
                let v = decodeURIComponent(value || '') // If a value is not defined, it should be decoded as an empty string
                value = v
            } catch (e) { console.warn(e) }
            //console.log(key, value)
            if (key.length > 1) {
                let d = value
                for (let i = key.length - 1; i >= 0; i--) {
                    const k = key[i]
                    if (!k || k === '0') {
                        let a = []
                        a.push(d)
                        d = a
                    } else {
                        d = {[k]: d}
                    }
                }

                //console.log('~ ', key, JSON.stringify(d))
                let _decoded = decoded
                for (let i = 0; i < key.length; i++) {
                    const k = key[i]
                    //console.log(i, '"' + k + '"', k in _decoded ? 'exists' : 'new')
                    if (k in _decoded && typeof _decoded[k] === 'object' && typeof d[k] === 'object') {
                        if (_decoded[k] instanceof Array) {
                            if (!isNaN(k) || d[k] instanceof Array) {
                                //console.log('"' + k + '"', 'is array', d[k], _decoded[k])
                                _decoded[k] = _decoded[k].concat(Object.values(d[k]).filter(v => v !== null))
                            } else {
                                //console.log('"' + k + '"', 'to object', d[k], _decoded[k])
                                _decoded[k] = Object.assign({}, _decoded[k], d[k])
                            }
                            //console.log('_decoded[k]', _decoded[k])
                            break
                        } else {
                            //console.log('"' + k + '"', 'is object', JSON.stringify(_decoded[k]), JSON.stringify(d[k]))
                            _decoded = _decoded[k]
                            d = d[k]
                        }
                    } else {
                        //console.log('assign ', '"' + k + '"')
                        Object.assign(_decoded, d)
                        //console.log('after assign', decoded);
                        break;
                    }
                }
            } else decoded[key[0]] = value
        }
        //console.log('decoded', JSON.stringify(decoded))
        return decoded;
    },
    /**
     * @param {{object}} dictionaries
     * @param {string} key
     * @param {...string} params
     * @returns {null|*}
     */
    getTranslationFromDictionary(dictionaries, key, ...params) {
        let str = false
        for (let type in dictionaries) {
            if (
                Object.prototype.hasOwnProperty.call(dictionaries, type) &&
                Object.prototype.hasOwnProperty.call(dictionaries[type], key) &&
                dictionaries[type][key]
            ) {
                str = dictionaries[type][key]
                break
            }
        }

        if (str) {
            let ix = 0
            return str.replace(/%(?:([0-9]+)\$)?s/g, (match, num) => params[isNaN(num) ? ix++ : num - 1] || '?')
        } else if (!key || Object.keys(dictionaries.global || {}).length) {
            return null
        } else return key.charAt(0).toUpperCase() + key.substr(1)
    },

    /**
     * @param {{locale: string}[]} languages
     * @param {string} primary
     * @return {string[]}
     */
    orderedLocaleList(languages, primary) {
        const ordered = (primary ? [primary] : []).concat(
            navigator?.languages || [],
            languages.map(l => l.locale)
        )
        ordered.forEach((lg,ix,array) => {
            lg = lg.replace('-', '_')
            if (array.indexOf(lg) === -1) array.push(lg)

            if (lg.length === 2) {
                let tmp = lg.toLowerCase() + '_' + lg.toUpperCase()
                if (array.indexOf(tmp) === -1) array.push(tmp)
            } else if (lg.length === 4) {
                let tmp = lg.split('_')[0].toLowerCase()
                if (array.indexOf(tmp) === -1) array.push(tmp)
            }
        })
        return ordered
    },
    /**
     * @param {{locale: string}[]} languages
     * @param {string[]} ordered
     * @return {{locale: string}[]}
     */
    sortLanguages(languages, ordered) {
        const sorted = []

        ordered.forEach(locale => {
            const tmp = languages.find(l => l.locale === locale)
            if (tmp && !sorted.find(l => l.locale === locale)) sorted.push(tmp)
        })
        // push unsortable languages
        languages.forEach(lg => {
            if (!sorted.find(l => l.locale === lg.locale)) sorted.push(lg)
        })
        return sorted
    },
    /**
     * @param {string} locale
     */
    setHTMLLang(locale) {
        document.documentElement.setAttribute('lang', locale.replace('_' , '-') || 'en')
    },
    /**
     * @param {string} locale
     * @param {boolean} nocache
     */
    updateFaviconLocale(locale, nocache = false) {
        const links = document.querySelectorAll('link[rel$="icon"]')
        if (nocache) updateFaviconUnique = Date.now()
        for (let link of links) {
            let href = link.href.replace(/^(.+)(\?.*)$/, '$1')
            link.href = href + '?lg=' + locale + '&' + updateFaviconUnique
        }
    },
    /**
     * @param {HTMLElement} node
     * @param {String|String[]} className
     * @param {HTMLElement} container
     * @returns {Boolean}
     */
    isChildOf: function (node, className, container) {
        let parent = node
        className = className instanceof Array ? className : [className]
        while (parent && parent !== container) {
            for (let i = 0; i < className.length; i++) {
                if (parent.classList?.contains(className[i])) return true
            }
            parent = parent.parentNode
        }
        return false
    },
    /**
     * @returns {Boolean}
     */
    isUserAgentIOS: function checkForIOS() {
        return !!navigator.userAgent.match(/(iPod|iPhone|iPad)/)
    },

    /**
     * @param {Event} event
     * @returns {Boolean}
     */
    isControlKeyPressed: function (event) {
        if (navigator.userAgent.indexOf('Mac OS X') !== -1) {
            if (event.metaKey && !event.altKey) return true
        } else if (event.ctrlKey && !event.altKey) return true
        return false
    },

    /**
     * Check the brightness from background color to return white text color when background color is to dark
     * @param bgColor
     * @returns {null|string}
     */
    getTextColor: function (bgColor) {
        if (!bgColor) return null

        const r = parseInt(bgColor.slice(1, 3), 16)
        const g = parseInt(bgColor.slice(3, 5), 16)
        const b = parseInt(bgColor.slice(5, 7), 16)
        const brightness = (r * 299 + g * 587 + b * 114) / 1000

        return brightness > 145 ? null : 'var(--white)'
    }
}