<template>
    <div id="messageDropdown"
         :class="showDropdown ? 'active' : null"
        class="dropdown-menu-container float-right"
        v-click-outside="hideDropdown"
        >
        <button class="btn-std btn-icon-only btn-view dropdown-menu-btn"
            v-tooltip="$root.l10n('notification')"
            @click="showDropdown = !showDropdown"
        >
            <IconSolid icon="bell" />
        </button>

        <div v-show="showDropdown" class="dropdown-menu-list">
            <div v-if="notificationsVisible">
                <template v-for="notification in notifications">
                    <AlertBox
                        v-if="notification.visible"
                        :key="notification.id"
                        :type="notification.type"
                        :icon="notification.icon"
                        :title="notification.title"
                        :text="notification.info"
                    >
                        <template #button>
                            <div
                                class="btn-std btn-icon-only"
                                @click.stop="hideNotification(notification.id)"
                            >
                                <IconLight icon="times" />
                            </div>
                        </template>
                    </AlertBox>
                </template>
            </div>
            <AlertBox v-else type="info" icon="exclamation" :title="$root.l10n('no_notifications')"/>
        </div>
    </div>
</template>

<script>

import IconSolid from '../../utility/IconSolid.vue'
import {directive as ClickOutside} from 'click-outside-vue3'
import AlertBox from "../../utility/AlertBox";
import IconLight from "../../utility/IconLight";

export default {
    name: "NotificationDropdown",
    components: {
        IconLight,
        AlertBox,
        IconSolid
    },
    data () {
        return {
            showDropdown: false,
            notificationsVisible: false,
            notifications: [
                {
                    id: 1,
                    type: 'danger',
                    icon: 'times',
                    title: 'Die Datei konnte nicht ersetzt werden.',
                    info: '',
                    visible: true
                },
                {
                    id: 2,
                    type: 'info',
                    icon: 'upload',
                    title: 'Die Datei wird hochgeladen.',
                    info: '<div class="progress-bar-container progress-with-value">' +
                    '<div role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" class="progress-bar" style="width:50%;"></div>' +
                    '<div class="value-percent">50%</div>' +
                    '</div>Adobe_234248.jpg<br>ID: 4792<br>',
                    visible: true
                },
                {
                    id: 3,
                    type: 'success',
                    icon: 'check',
                    title: 'Die Datei wurde erfolgeich aktualisiert.',
                    info: '</div>Adobe_234248.jpg<br>ID: 4792<br>',
                    visible: true
                }
            ]
        }
    },
    directives: {
        ClickOutside
    },
    methods: {
        checkNotificationsVisibility (){
            let visible = false
            for (let i = 0; i < this.notifications.length; i++) {
                visible = visible || this.notifications[i].visible
            }
            this.notificationsVisible = visible
        },
        hideNotification (notificationId) {
            for (let i = 0; i < this.notifications.length; i++) {
                if (this.notifications[i].id == notificationId) {
                    this.notifications[i].visible = false
                    break
                }
            }
            this.checkNotificationsVisibility()
        },
        closeDropdownsOnEsc (e) {
            if (e.keyCode === 27) this.hideDropdown()
        },
        hideDropdown(){
            this.showDropdown = false
        }
    },
    mounted() {
        this.checkNotificationsVisibility()
        document.addEventListener('keydown', this.closeDropdownsOnEsc)
    },
    unmounted() {
        document.removeEventListener('keydown', this.closeDropdownsOnEsc)
    },
}
</script>