<template>
    <button :type="type"
        class="btn-std"
        :class="additionalClasses"
        :disabled="disabled"
        :aria-label="label || ariaLabel"
        @click="$emit('click', $event)"
    >
        <slot />
    </button>
</template>

<script>
export default {
    name: "ButtonStd",
    emits: {click: null},
    props: {
        type: {
            type: String,
            default: 'button'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: String,
        ariaLabel: String,
        primary: {
            type: Boolean,
            default: false
        },
        adaptive: {
            type: Boolean,
            default: false
        },
        iconOnly: {
            type: Boolean,
            default: false
        },
        iconWithText: {
            type: Boolean,
            default: false
        },
        weight: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        additionalClasses () {
            return [
                (this.weight ? this.primary ? 'btn-primary' : 'btn-secondary' : ''),
                (!this.adaptive ? this.iconOnly ? 'btn-icon-only' : null : 'btn-adaptive'),
                (this.iconWithText ? 'icon-with-text' : null),
            ].join(' ')
        }
    }
}
</script>
