<template>
    <div>
        <AlertBox type="info" :title="message" :text="text" />
        <div class="popup-toolbar">
            <ModalButton
                :type="'close'"
                :icon="'times'"
                @clicked="onClick(false)"
            />
            <ModalButton
                :type="confirmLLKey"
                :primary="true"
                :class="'float-right'"
                :icon="'check'"
                @clicked="onClick(true)"
            />
        </div>
    </div>
</template>

<script>
import ModalButton from './ModalButton'
import AlertBox from '../../utility/AlertBox'
import {computed} from 'vue'

export default {
    name: "Confirm",
    components: {
        AlertBox, ModalButton
    },
    props: {
        message: String,
        text: String,
        confirmBtn: String
    },
    setup(props) {
        return {
            confirmLLKey: computed(() => props.confirmBtn || 'ok')
        }
    },
    methods: {
        onClick(res) {
            this.$emit('confirm', res)
            this.$emit('close')
        }
    }
}

</script>