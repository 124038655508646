<template>
    <component :is="tag" @click="onClick" :class="error ? 'btn-danger' : null">
        <teleport to="body"><iframe v-if="frame"
            id="download-container"
            frameborder="0"
            scrolling="no"
            position="absolute"
            marginwidth="0"
            marginheight="0"
            vspace="0"
            hspace="0"
            width="0"
            height="0"
            style="position: absolute; top:-9999999px; left:-9999999px;"
            @onload="onload"
            ref="iframe"
            :src="downloadSource"
        /></teleport>
        <LoadingSpinner v-if="loading"/>
        <a v-else-if="downloadSource && isIOS && !native"
           :href="downloadSource"
           @click="$emit('complete', 'click')"
           target="_blank" download
           class="text-link link-blue"
        >
            <span class="text-alert alert-info">{{ $root.l10n('start_download_by_click') }}</span>
        </a>
        <slot v-else-if="error" name="error">
            <IconLight icon="exclamation" v-tooltip="error"/>
        </slot>
        <slot v-else />
    </component>
</template>

<script>
import LoadingSpinner from '../layout/LoadingSpinner'
import IconLight from './IconLight'
import { isUserAgentIOS } from '../../lib/utility'
import ajax from '../../lib/ajax'

const { FileObject, MediaObject } = require('../../lib/models/object')

export default {
    name: "Download",
    components: {
        LoadingSpinner,
        IconLight
    },
    props: {
        init: {
            type: String, //mount, click
            default: 'click'
        },
        tag: {
            type: [String, Function],
            default: 'BUTTON'
        },
        src: [FileObject, Object, String],
        native: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            isIOS: isUserAgentIOS(),
            loading: false,
            error: null,
            frame: false,
            downloadSource: null
        }
    },
    methods: {
        onClick(event) {
            if (!this.loading && this.init === 'click') {
                event.preventDefault()
                this.download()
                return false
            }
        },
        download () {
            if (this.downloadSource){
                this.loading = true
                this.frame = false
                this.$nextTick(() => {
                    this.loading = false
                    this.downloadInBackground(this.downloadSource)
                })
            } else if (this.src instanceof FileObject) {
                this.loading = true
                const route = this.src instanceof MediaObject ? 'media.getLink' : 'file.getLink'
                const link = this.$root.getRoutePath(route, this.src.moduleTabId, this.src.id)

                ajax.json(link).then(({response}) => {
                    this.loading = false
                    this.downloadInBackground(response.location)
                }).catch(e => {
                    this.loading = false
                    this.error = e.message || e
                })
            } else if (this.src instanceof Object) {
                const link = this.$root.getRoutePath('download.getAttachment', this.src.Hash)
                this.downloadInBackground(link)
            } else if (this.src) {
                this.downloadSource = this.src
                this.downloadInBackground(this.src)
            }
        },
        downloadInBackground (url) {
            this.downloadSource = url
            if (this.isIOS && this.native) {
                this.frame = false
                window.location.assign(url)
                this.$emit('complete', 'self')

            } else if (this.isIOS) {
                this.frame = false
                window.open(url, '_blank')
                this.$emit('complete', 'blank')

            } else {
                this.frame = true
                this.$nextTick(() => {
                    this.checkLoad()
                    this.$emit('complete', 'frame')
                })
            }

        },
        onload(e) {
            console.log(e)
        }
        ,checkLoad() {
            const iframe = this.$refs.iframe
            console.log('checkLoad', iframe);
            const iframe_onload = setInterval(() => {
                const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document || {readyState: 'complete'};
                if (iframeDoc.readyState == 'complete') {
                    console.log('complete', iframeDoc.readyState);
                    window.clearInterval(iframe_onload);
                } else console.log(iframeDoc.readyState);
            }, 500)
        }

    },
    mounted () {
        if (this.init === 'mount') {
            this.download()
        }
    }
}
</script>