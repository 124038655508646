<template>
    <TabPanel :active="active">
        <SearchOption
            :tag="FormSection"
            :modifier="modifier"
            :equality="equality"
            :modelValue="modelValue"
            @update:modelValue="$emit('update:modelValue', $event)"
        >
            <slot name="extension" />

            <template #info>
                Hilfe text
                <slot name="help-addon" />
            </template>
        </SearchOption>
    </TabPanel>
</template>

<script>
import { markRaw } from 'vue'
import TabPanel from '../../../forms/TabPanel'
import FormSection from '../../../forms/FormSection'
import SearchOption, { equality, modifier } from './SearchOption'

export {modifier}

export default {
    name: "SearchOptionTab",
    props: {
        modelValue: Object,
        active: Boolean,
        modifier: Array,
    },
    components: {
        TabPanel,
        SearchOption
    },
    data() {
        return {
            FormSection: markRaw(FormSection),
            equality: [equality.flex, equality.not]
        }
    }
}
</script>