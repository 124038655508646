<template>
    <ElementGroup>
        <InputWrapper
            :label="label"
            :label-for="id"
            :helpText="helpText"
            :focused="focused"
            :disabled="disabled"
            :required="required"
            :errors="errors"
            :resettable="isDirty"
            @focus="$refs.input.focus()"
            @reset="reset"
        >
            <template #status-bar-additional><slot name="status-bar-additional" /></template>
            <input
                :id="id"
                :type="actualType"
                :name="name"
                :placeholder="placeholder"
                :required="required || null"
                :disabled="disabled || null"
                :min="type === 'number' && min || null"
                :max="type === 'number' && max  || null"
                :step="type === 'number' && step || null"
                :minlength="minlength"
                :maxlength="maxlength"
                :autofocus="autofocus"
                :autocomplete="autocomplete ? null : 'off'"
                :data-1p-ignore="autocomplete ? null : ''"
                :value="modelValue"
                @input="oninput"
                @focus="onfocus"
                @blur="onblur"
                @keydown="keydown"
                ref="input"
            />
            <template #additional>
                <button v-if="(showClearBtn || value) && !disabled && erasable" type="button" class="clear-btn" @click.stop="clearInput" tabindex="-1"><IconLight icon="times"/></button>
                <template v-if="type === 'number' && !disabled">
                    <div class="step-btns">
                        <button type="button" :class="value >= max && max !== null ? 'disabled' : null" @mousedown.left="() => startCount(true)" @mouseleave="stopCount" @mouseup="stopCount" @touchstart="startCount($event,true)" @touchend="stopCount" @touchcancel="stopCount" tabindex="-1"><IconSolid icon="caret-up"/></button>
                        <button type="button" :class="value <= min  && min !== null ? 'disabled' : null" @mousedown.left="() => startCount(false)" @mouseleave="stopCount" @mouseup="stopCount" @touchstart="startCount(false)" @touchend="stopCount" @touchcancel="stopCount" tabindex="-1"><IconSolid icon="caret-down"/></button>
                    </div>
                </template>
                <template v-if="type === 'range'">
                    <span class="span-range-unit">{{modelValue + unit}}</span>
                </template>
                <slot/>
            </template>
        </InputWrapper>
        <template #additional><slot name="info" /></template>
    </ElementGroup>
</template>

<script>
import ElementGroup from '../ElementGroup'
import InputWrapper from '../InputWrapper'
import DefaultValue from '../../../lib/mixins/defaultValue'
import IconSolid from "@/components/utility/IconSolid";
import IconLight from "@/components/utility/IconLight";

export default {
    name: "TextInput",
    components: {IconLight, IconSolid, ElementGroup, InputWrapper},
    mixins: [DefaultValue],
    props: {
        label: String,
        modelValue: [String, Number],
        type: {
            type: String,
            default: 'text'
        },
        id: String,
        name: String,
        placeholder: String,
        required: Boolean,
        disabled: Boolean,
        erasable: {
            type: Boolean,
            default: true
        },
        emptyValue: {
            type: [String, Number],
            default: null
        },
        min: Number,
        max: Number,
        minlength: Number,
        maxlength: Number,
        step: {
            type: Number,
            default: 1
        },
        unit: String,
        autofocus: Boolean,
        autocomplete: {
            type: Boolean,
            default: false
        },
        errors: [Object,Array,String],
        helpText: String,
        showClearBtn: Boolean
    },
    data() {
        return {
            focused: false,
            interval: false
        }
    },
    computed: {
        actualType () {
            switch (this.type.toLowerCase()) {
                case 'number': return 'tel' //@see https://stackoverflow.com/a/18678386
                default: return this.type
            }
        },
        value() { return this.modelValue } //vue2 fallback
    },
    methods: {
        /**
         * @param {boolean} direction - true for increment, false - decrement
         */
        count(direction) {
            const val = ((!this.value || isNaN(this.value)) ? 0 : parseInt(this.value)) + (direction ? +this.step : -this.step)
            this.correctValueByBounds(val)
        },
        /**
         * @param {boolean} direction - true for increment, false - decrement
         */
        startCount(direction) {
            this.count(direction)
            if (!this.interval) {
                this.interval = setInterval(() => this.count(direction), 150)
            }
        },
        /** */
        stopCount() {
            clearInterval(this.interval)
            this.interval = false
        },
        /** */
        clearInput() {
            let value  = ''
            if (this.type === 'number' && this.required) {
                value = this.emptyValue !== null ? this.emptyValue : this.min !== null ? this.min : ''
            }
            this.$emit('update:modelValue', value)
        },
        /** @param {string|number} value */
        correctValueByBounds(value) {
            if (value !== '' || this.required) value = this.limitValueByBounds(value)
            if ('' + this.modelValue !== value + '') this.$emit('update:modelValue', '' + value)
        },
        /**
         * @param {string} value
         * @returns {number}
         */
        limitValueByBounds(value) {
            if (this.min !== undefined && this.min !== null && !isNaN(value)) value = Math.max(value, this.min)
            if (this.max !== undefined && this.min !== null && !isNaN(value)) value = Math.min(value, this.max)
            return value
        },
        /**
         * @param {string} value
         * @returns {string|number}
         */
        unformatValue(value) {
            if (this.type === 'number') {
                //return parseInt(value.replace(new RegExp(this.thousandSep, 'g'), '') || 0, 10)
                return value ? parseInt(value, 10) : 0
            } else return value
        },
        /**
         * @param {string|number} value
         * @returns {string}
         */
        formatValue(value) {
            if (this.type === 'number') {
                value = value.toString()
                let sign = value.indexOf('-') > -1 ? '-' : ''
                value = sign + value.replace(/[^0-9]/g, '')
                //    .replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandSep)
            }
            return value
        },
        /**
         * @param {KeyboardEvent} event
         */
        oninput(event) {
            let value = event.target.value
            if (this.type === 'number') {
                value = this.formatValue(value)
            }

            if (value !== event.target.value) this.fixTargetValue(event, value)
            this.$emit('update:modelValue', value)
        },
        /**
         * @param {Event} event
         */
        onfocus(event) {
            this.$emit('focus', event, this.focused = true)
            if (this.type === 'password' && this.defaultValue === this.modelValue && this.modelValue) {
                this.$emit('update:modelValue', '')
            }
        },
        /**
         * @param {Event} event
         */
        onblur(event) {
            this.$emit('blur', event, this.focused = false)
            if (this.type === 'password' && !(this.modelValue || event.target.value)) {
                this.$emit('update:modelValue', this.defaultValue)
            }
        },
        /**
         * @param {KeyboardEvent} event
         */
        keydown(event) {
            if (this.type === 'number') {
                let value = this.unformatValue(event.target.value)
                let changed = value
                if (isNaN(value)) return ;
                
                switch (event.key) {
                    case 'ArrowUp':
                        changed = this.limitValueByBounds(value + this.step)
                        break
                    case 'ArrowDown': //keyDown
                        changed = this.limitValueByBounds(value - this.step)
                        break
                }

                if (changed !== value) {
                    value = this.formatValue(changed)
                    this.fixTargetValue(event, value)
                    this.$emit('update:modelValue', value)
                    event.preventDefault()
                }
            }
        },
        /**
         * set target value, saving caret position
         * @param {KeyboardEvent} event
         * @param {string} value
         */
        fixTargetValue(event, value) {
            let selStart = Math.max(0, event.target.selectionStart)
            let selEnd = Math.max(selStart, event.target.selectionEnd)
            if (event.key === '-') {
                selStart += 1; selEnd += 1
            } else if (selEnd - selStart === event.target.value.length) {
                selStart = 0; selEnd = value.length
            } else {
                let fix = value.length - event.target.value.length
                selStart += fix; selEnd += fix
            }
            event.target.value = value
            event.target.setSelectionRange(selStart, selEnd)
        }
    },
    watch: {
        modelValue(value) {
            this.correctValueByBounds(value)
        },
        min() {
            this.correctValueByBounds(this.modelValue)
        },
        max() {
            this.correctValueByBounds(this.modelValue)
        }
    }
}
</script>