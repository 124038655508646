<template>
    <ElementGroup class="form-element switch-input">
        <InputWrapper v-if="expanded"
            :label="label"
            :helpText="helpText"
            :disabled="disabled"
            :required="required"
            :errors="errors"
            :resettable="isDirty"
            @reset="reset"
        >
            <label class="switch" :class="trafficLight ? 'switch-traffic-light' : null">
                <input
                    type="checkbox"
                    :disabled="disabled"
                    :checked="!!modelValue"
                    @input="$emit('update:modelValue', $event.target.checked)"
                />
                <span class="slider round"></span>
                <span class="switch-option" :style="{ backgroundColor: color }">{{ option || activate }}</span>
                <span v-if="description" class="switch-description">{{ description }}</span>
            </label>
            <template #additional>
                <button v-if="modelValue && !disabled && erasable" type="button" class="clear-btn" @click.stop="clearInput" tabindex="-1">
                    <IconLight icon="times"/>
                </button>
                <slot/>
            </template>
        </InputWrapper>
        <template v-else>
            <label class="switch" :class="trafficLight ? 'switch-traffic-light' : null" :disabled="disabled">
                <span class="additional float-right"><slot/></span>
                <input
                    type="checkbox"
                    :disabled="disabled"
                    :checked="!!modelValue"
                    @input="$emit('update:modelValue', $event.target.checked)"
                />
                <span class="slider round"></span>
                <span class="switch-label">
                    <span v-if="color" :style="{ backgroundColor: color }">{{ label }}</span>
                    <template v-else>{{label}}</template>
                    <span v-if="helpText" class="label-button help-text" v-tooltip="helpText"><IconLight icon="info-circle" /></span>
                </span>
            </label>
        </template>
        <p v-if="description && !expanded" class="switch-description margin-top-small">{{ description }}</p>
        <template #additional><slot name="info" /></template>
    </ElementGroup>
</template>

<script>
import ElementGroup from '../ElementGroup.vue'
import InputWrapper from '../InputWrapper.vue'
import IconLight from '../../utility/IconLight'
import DefaultValue from '../../../lib/mixins/defaultValue'

export default {
    name: "SwitchInput",
    components: {InputWrapper, ElementGroup, IconLight},
    mixins: [DefaultValue],
    props: {
        modelValue: Boolean,
        description: String,
        label: String,
        disabled: {
            type: Boolean,
            default: false
        },
        erasable: {
            type: Boolean,
            default: true
        },
        trafficLight: {
            type: Boolean,
            default: false
        },
        option: String,
        required: Boolean,
        errors: [Object, Array, String],
        helpText: String,
        color: String,
        expanded: Boolean
    },
    data() {
        return {
            activate: this.$root.l10n('activate')
        }
    },
    methods: {
        /** @override DefaultValue */
        reset() {
            this.$emit('update:modelValue', this.defaultValue)
        },
        /** */
        clearInput() {
            this.$emit('update:modelValue', false)
        }
    }
}
</script>