<template>
    <component :is="tag">
        <div class="grid-container">
            <span class="grid-description">
                <label>{{$root.l10n('free_text_search_settings')}} (global)</label>
            </span>
            <div class="grid-filters">
                <div v-for="option in juncOptions" :key="option.value">
                    <ChoiceBox type="radio" :checked="modelValue.j === option.value" @click="onChange({j: option.value})">
                        {{option.title}}
                    </ChoiceBox>
                </div>
            </div>
            <div class="grid-description">
                <label>{{$root.l10n('free_text_search_settings')}}</label>
            </div>
            <div class="grid-filters">
                <div v-for="option in seqOptions" :key="option.value">
                    <ChoiceBox type="radio" :checked="modelValue.seq === option.value" @click="onChange({seq: option.value})">
                        {{option.title}}
                    </ChoiceBox>
                </div>
            </div>
            <div class="grid-filters">
                <div v-for="option in eqOptions" :key="option.value">
                    <ChoiceBox type="radio" :checked="modelValue.eq === option.value" @click="onChange({eq: option.value})">
                        {{option.title}}
                    </ChoiceBox>
                </div>
            </div>
            <template v-if="modOptions.length">
                <div class="grid-description">
                    <label>{{$root.l10n('search_collation')}}</label>
                </div>
                <div class="grid-filters">
                    <div v-for="option in modOptions"
                        :key="option.value"
                    >
                        <ChoiceBox
                            type="checkbox"
                            :checked="modifiers.indexOf(option.value) > -1"
                            @click="onModChange(option.value)"
                        >
                            {{option.title}}
                        </ChoiceBox>
                    </div>
                </div>
            </template>
            <slot name="additional" />
        </div>
    </component>
</template>

<script>
import FormRow from '../../../forms/FormRow'
import ChoiceBox from '../../../forms/inputs/ChoiceBox'

const junction = {
    and: 'AND',
    or: 'OR'
}
const sequence = {
    bounded: 'b',
    partial: 'p',
    exact: 'e'
}
const equality = {
    strict: 's',
    flex: 'f',
    not: 'n'
}
const modifier = {
    case: 'c',
    diacritical: 'u',
    extended: 'e'
}
/**
 * @param {string} string
 * @param {char} value
 * @param {boolean} force=undefined
 * @returns {string}
 */
const toggleModifier = function(string, value, force) {
    const match = string.indexOf(value) > -1
    if (match && force !== true) {
        string = string.replace(value, '')
    } else if (!match && force !== false) {
        string = string ? string.split('') : []
        string.push(value)
        string.sort()
        string = string.join('')
    }
    return string
}

export default {
    name: "SearchOption",
    props: {
        /** @var {{j: AND|OR, seq: string, eq: boolean}} */
        modelValue: {
            type: Object,
            default() {
                return {j: junction.and, seq: sequence.exact, eq: equality.flex}
            }
        },
        tag: {
            type: [String,Object,Function],
            default () { return 'DIV' }
        },

        junction: Array,
        sequence: Array,
        equality: {
            type: Array,
            default () {
                return [equality.flex, equality.strict, equality.not]
            }
        },
        modifier:  {
            type: Array,
            default () {
                return [modifier.case, modifier.diacritical]
            }
        },

    },
    components: {
        ChoiceBox,
        FormRow,
    },
    data() {
        return {
            juncStdOptions: [
                {value: junction.and, title: this.$root.l10n('search_operator_and')},
                {value: junction.or, title: this.$root.l10n('search_operator_or')}
            ],
            seqStdOptions: [
                {value: sequence.bounded, title: this.$root.l10n('search_word_bound')}, // \bWORD\b
                {value: sequence.partial, title: this.$root.l10n('search_word_part')},// *WORD*
                {value: sequence.exact, title: this.$root.l10n('search_word_exact')} // "WORD"
            ],
            eqStdOptions: [
                {value: equality.strict, title: this.$root.l10n('search_equal')},
                {value: equality.not, title: this.$root.l10n('search_not_equal')},
                {value: equality.flex, title: this.$root.l10n('search_flex_equal')},
            ],
            modStdOptions: [
                {value: modifier.case, title: this.$root.l10n('search_case_insensitive')},
                {value: modifier.diacritical, title: this.$root.l10n('search_diacritical_ignore')},
                {value: modifier.extended, title: this.$root.l10n('search_extended')} // DAM: search in file content
            ]
        }
    },
    computed: {
        /** @returns {{value: string, title: string}[]} */
        juncOptions () {
            return this.junction ? this.juncStdOptions.filter(o => this.junction.includes(o.value)) : this.juncStdOptions
        },
        /** @returns {{value: string, title: string}[]} */
        seqOptions () {
            return this.sequence ? this.seqStdOptions.filter(o => this.sequence.includes(o.value)) : this.seqStdOptions
        },
        /** @returns {{value: string, title: string}[]} */
        eqOptions () {
            return this.equality ? this.eqStdOptions.filter(o => this.equality.includes(o.value)) : this.eqStdOptions
        },
        /** @returns {{value: string, title: string}[]} */
        modOptions () {
            return this.modifier ? this.modStdOptions.filter(o => this.modifier.includes(o.value)) : this.modStdOptions
        },
        /** @returns {string} */
        modifiers () {
            return this.modelValue.m || ''
        },
    },
    methods: {
        __is_unequal(a, b) {
            const entries = Object.entries(a)
            return entries.length !== Object.entries(b).length || entries.some(([k,v]) => v !== b[k])
        },
        onChange(value, tst) {
            const options = Object.assign({
                j: this.getDefaultJunction(),
                seq: this.getDefaultSequence(),
                eq: this.getDefaultEquality(),
                m: this.getDefaultModifier(),
            }, this.modelValue, value)

            console.log('SearchOption.onChange', JSON.stringify(this.modelValue), JSON.stringify(options))
            if (this.__is_unequal(this.modelValue, options)) {
                console.log('SearchOption.$emit.update:modelValue', JSON.stringify(options))
                this.$emit('update:modelValue', options)
            }
        },
        onModChange(value) {
            this.onChange({m: toggleModifier(this.modifiers, value)})
        },
        onPropertiesChanged() {
            const value = {}
            if (!this.juncOptions.some(o => o.value === this.modelValue?.j))
                value.j = this.getDefaultJunction()

            if (!this.seqOptions.some(o => o.value === this.modelValue?.seq))
               value.seq = this.getDefaultSequence()

            if (!this.eqOptions.some(o => o.value === this.modelValue?.eq))
                value.eq = this.getDefaultEquality()

            if (Object.keys(value).length > 0) 
                this.onChange(value)
        },

        getDefaultJunction () {
            if (!this.juncOptions.some(o => o.value === junction.and))
                return (this.juncOptions[0] || {}).value || junction.and
            else return junction.and
        },
        getDefaultSequence () {
            if (!this.seqOptions.some(o => o.value === sequence.bounded))
                return (this.seqOptions[0] || {}).value || sequence.bounded
            else return sequence.bounded
        },
        getDefaultEquality () {
            if (!this.eqOptions.some(o => o.value === equality.flex))
                return (this.eqOptions[0] || {}).value || equality.flex
            else return equality.flex
        },
        getDefaultModifier () {
            let m = ''
            if (this.modOptions.some(o => o.value === modifier.case))
                m += modifier.case
            if (this.modOptions.some(o => o.value === modifier.diacritical))
                m += modifier.diacritical

            if (!m) m = modifier.case + modifier.diacritical;
            return m
        }
    },
    watch: {
        junction () {
            this.onPropertiesChanged()
        },
        sequence (to, from) {
            if (to.join(',') === from.join(',')) return
            this.onPropertiesChanged()
        },
        equality (to, from) {
            if (to.join(',') === from.join(',')) return
            this.onPropertiesChanged()
        },
        modelValue (to, from) {
            console.log('SearchOption.watch.modelValue', JSON.stringify(to))
            if (JSON.stringify(to) === JSON.stringify(from)) return
            this.onPropertiesChanged()
        }
    },
    created () {
        console.debug(this.modelValue, !(!this.modelValue || !Object.values(this.modelValue).some(v => !!v)))
        if (!this.modelValue || !Object.values(this.modelValue).some(v => !!v)) this.onChange({})
        else this.onPropertiesChanged()
    }
}

export {
    junction,
    sequence,
    equality,
    modifier,
    toggleModifier
}
</script>

<style>
.grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
}

.grid-description {
    grid-column: 1 / -1;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px !important;
    line-height: 1.4rem !important;
    color: var(--color-text) !important;
}

.grid-filters {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 10px;

    &+ .grid-filters {
        border-top: thin solid var(--grey_20);
        padding-top: 1rem;
    }

    .checkingbox-container .text-label {
        white-space: normal;
    }
}

.dropdown-menu-list-content .grid-container {
    padding: 1rem 0;
}
</style>