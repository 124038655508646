const key = Symbol('observer')

/**
 * @export
 * @see https://vuejs.org/guide/reusability/custom-directives.html
 */
export default {
    /**
     * @param {Element} el
     * @param {{value: any, modifiers: object}} binding
     * @param {VNode} vnode
     */
    mounted(el, binding, vnode) {
        el[key] = new ResizeObserver(entries => {
            window.requestAnimationFrame(() => {
                if (!Array.isArray(entries) || !entries.length) {
                    return;
                }
                if (typeof binding.value === 'function')
                    binding.value(entries)
            })
        })
        el[key].observe(el)
    },
    beforeUnmount(el) {
        el[key]?.disconnect()
    }
}
