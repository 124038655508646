<template>
    <div id="similarMediaPanelContent">
        <LoadingSpinner v-if="uploading === true" class="margin-top" />
        <AlertBox v-else-if="uploading instanceof Error"
            type="danger" icon="xmark" @click="uploading = false"
            :text="uploading.message"
        />
        <div v-show="!uploading" class="headline">
            {{ $root.l10n('search_in_database_for_similar_images') }}
            <button class="float-right" @click.prevent="$emit('close')"><IconLight icon="xmark" /></button>
        </div>
        <div v-show="!uploading" class="content">
            <uploader class="similar-media-upload"
                :id="'search_upload'"
                :autoUpload="true"
                :multiple="false"
                :disabled="disabled"
                :dropzoneDisabled="false"
                :thumbnailSize="thumbnailSize"
                acceptFiles="image/jpeg,image/gif,image/png,image/bmp,image/webp"
                @added="uploading=true"
                @complete="onComplete"
                @uploads="uploads = $event"
                ref="uploader"
            >
                <template #upload-drop-area-icon>
                    <IconThin icon="image" />
                </template>
                <template #filter><div hidden></div></template>
                <template #progress-total><div hidden></div></template>

                <template #uploaded></template>

                <template #no-uploads>
                    <div class='qq-thumbnail-wrapper'>
                        <IconLight icon="image" class="border-circle"/>
                    </div>
                </template>
            </uploader>
            <div class="line-back">
                <span>{{ $root.l10n('or') }}</span>
            </div>
            <div class="input-url">
                <input type="text"
                    :disabled="disabled"
                    :placeholder="$root.l10n('insert_an_image_url')"
                    ref="uploaderLink"
                    @keydown.enter.stop="(ev) => onUploadLink(ev.target.value)"
                />
                <button type="button" @click="() => onUploadLink($refs.uploaderLink.value)" :disabled="disabled">
                    <IconLight icon="search" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {shallowRef, ref} from 'vue'
import AlertBox from '../AlertBox'
import IconLight from '../IconLight'
import Uploader from './Uploader'
import LoadingSpinner from '../../layout/LoadingSpinner'
import ajax from '../../../lib/ajax'
import IconThin from '@/components/utility/IconThin.vue'

export default {
    name: "SimilarMediaUploader",
    emits: {complete: null, deleted: null},
    components: {
        IconThin,
        LoadingSpinner,
        AlertBox,
        Uploader,
        IconLight
    },
    props: {
        thumbnailSize: {
            default: 70
        },
        disabled: Boolean,
    },
    setup() {
        return {
            uploads: shallowRef([]),
            uploading: ref(false),
        }
    },
    methods: {
        /**
         * @param {number} id
         * @param {string} name
         * @param {boolean} result
         */
        onComplete (id, name, result) {
            this.uploading = false
            const upload = this.$refs.uploader.getUpload(id)
            if (!upload) {
                console.error('wtf?!')
            } else if (!result) {
                console.warn(result)
            } else {
                this.$emit('complete', upload.uuid)
            }
        },
        /**
         * @param {string} url
         */
        onUploadLink(url) {
            if (this.disabled) return ;
            this.uploading = true
            ajax.json(this.$root.getRoutePath('system.upload.link'), {data:{link: url}}).then(({response}) => {
                const {uuid, uploadName} = response
                this.uploading = false
                this.$emit('complete', uuid)
            }).catch(e => {
                console.error(e);
                this.uploading = new Error(e.message || e)
            })
        },
    }
}
</script>

<style lang="scss">
.upload-container.similar-media-upload {
    .qq-upload-head {
        padding: 0;
    }

    .qq-upload-button-selector {
        border: 0;
        background-color: transparent;

        .i {
            font-size: 54px;
            margin-right: 24px;
            color: var(--grey_60);
        }

        span.text-overflow {
            color: var(--grey_80);
        }
    }
}
</style>