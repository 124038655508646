<template>
    <div :id="id" class="upload-container">
        <div class="qq-uploader-selector qq-uploader qq-gallery">
            <div
                class="qq-upload-head qq-upload-drop-area text-overflow"
                :disabled="dropzoneDisabled ? 'true' : null"
                :multiple="multiple ? 'true' : 'false'"
                ref="dropZone"
            >
                <label v-if="label">{{ label }}</label>
                <div class="qq-upload-button-selector">
                    <input type="file"
                        @change="onFilesAdded"
                        :multiple="multiple ? true : null"
                        :accept="acceptFiles"
                        ref="input"
                    />
                    <slot name="upload-drop-area-icon">
                        <IconLight icon="cloud-upload-alt" />
                    </slot>
                    <span class="text-overflow">{{$root.l10n('select_files_or_drag_and_drop')}}</span>
                </div>
            </div>

            <slot name="filter" :change="visibilityFilterChange"  v-if="multiple">
                <ul class="qq-upload filter-tabs">
                    <li role="presentation"
                        v-for="({ state, label, count, classes }, index) in filterOptions"
                        :key="index"
                        :class="classes + (filter === state ? ' active' : '') + (count > 0 ? ' not-empty' : '')"
                    >
                        <a href="#" @click.prevent="visibilityFilterChange(state)">{{ label }} <span class="count-box">{{ count }}</span></a>
                    </li>
                </ul>
            </slot>

            <slot name="progress-total" :perventage="progress || 0">
                <progress-bar class='qq-total-progress-bar' :percentage="progress || 0" />
            </slot>

            <ul class="qq-upload-list-selector" role="list" aria-live="polite" aria-relevant="additions removals">
                <li v-if="visibleFiles.length === 0 && $slots['no-uploads']" ><slot name="no-uploads" /></li>
                <li v-for="upload in visibleFiles" :key="upload.id" :ref="'upload-item-'+upload.id" v-else>
                    <slot name="uploaded" :upload="upload">
                        <uploader-thumb
                            class='qq-thumbnail-wrapper'
                            :size="thumbnailSize"
                            :fromServer="upload.fromServer"
                            :uploader="uploader"
                            :id="upload.id"
                            :src="upload.thumb"
                            @input="$emit('thumbnail', upload.id, upload.thumb=$event)"
                        />
                        <div class="qq-file-info">
                            <div class="qq-file-info-text">
                                <div class="qq-file-name"><slot name="filename" :upload="upload">{{ upload.file.name }}</slot></div>
                                <div class="qq-file-size">{{  formatSize(upload.file.size) }}</div>
                            </div>
                            <progress-bar :percentage="upload.progress || 0"/>
                        </div>

                        <slot name="status" :upload="upload" @toggle="upload.actionsToggle">
                            <div
                                class="dropdown-menu-container upload-status"
                                v-click-outside="upload.actionsHide"
                                :data-upload-status="upload.status"
                            >
                                <ButtonStd v-if="upload.isProcessing() || upload.isReady() || upload.status === qqxStatus.PAUSED || upload.status === qqxStatus.QUEUED"
                                    icon-only class="dropdown-menu-btn"
                                    @click="() => {upload.actionsToggle(); $forceUpdate()}"
                                >
                                    <IconLight icon="ellipsis-h" />
                                </ButtonStd>
                                <ButtonStd v-else-if="upload.isFailed()"
                                    icon-only class="dropdown-menu-btn btn-danger"
                                    @click="() => {upload.actionsToggle(); $forceUpdate()}"
                                >
                                    <span>fehlgeschlagen</span>
                                    <IconLight icon="exclamation" />
                                </ButtonStd>
                                <slot v-else name="status-else" :upload="upload">
                                    <ButtonStd v-if="upload.status === qqxStatus.UPLOAD_SUCCESSFUL"
                                        icon-only class="dropdown-menu-btn btn-success"
                                        @click="() => {upload.actionsToggle(); $forceUpdate()}"
                                    >
                                        <IconLight icon="check" />
                                    </ButtonStd>
                                    <ButtonStd v-else
                                        icon-only class="dropdown-menu-btn btn-warning"
                                        @click="() => {upload.actionsToggle(); $forceUpdate()}"
                                    >
                                        <IconLight icon="exclamation" />
                                    </ButtonStd>
                                </slot>

                                <div v-show="upload.shown" class="dropdown-menu-list dropdown-menu-medium dropdown-menu-right-top">
                                    <div class="triangle"></div>
                                    <div class="dropdown-menu-list-content">
                                        <div class="dropdown-menu-list-header">
                                            <slot name="status-text" :upload="upload">
                                                <h3 v-if="upload.isReady()">
                                                    <IconLight icon="ellipsis-h" />
                                                    {{$root.l10n('ready_for_upload')}}
                                                </h3>
                                                <h3 v-else-if="upload.status === qqxStatus.QUEUED">
                                                    <IconLight icon="ellipsis-h"/>
                                                    {{$root.l10n('upload_queued')}}
                                                </h3>
                                                <h3 v-else-if="upload.status === qqxStatus.PAUSED">
                                                    {{$root.l10n('upload_paused')}}
                                                    <IconLight icon="ellipsis-h" />
                                                </h3>
                                                <h3 v-else-if="upload.isProcessing()">
                                                    <IconLight icon="ellipsis-h" />
                                                    {{$root.l10n('upload_in_progress')}}
                                                </h3>
                                                <h3 v-else-if="upload.isFailed()">
                                                    <IconLight icon="exclamation" class="text-alert alert-danger" />
                                                    {{$root.l10n('upload_failed')}}
                                                </h3>
                                                <h3 v-else-if="upload.status === qqxStatus.UPLOAD_SUCCESSFUL">
                                                    <IconLight icon="check" class="text-alert alert-success"/>
                                                    {{$root.l10n('upload_successfully')}}
                                                </h3>
                                                <h3 v-else>
                                                    <IconLight icon="exclamation" />
                                                    {{$root.l10n('upload_warning')}}
                                                </h3>
                                                <p v-if="upload.status === qqxStatus.SAVED">
                                                    {{$root.l10n('file_successfully_uploaded_and_saved')}}
                                                </p>
                                                <p v-if="upload.comment.length > 0">
                                                    {{upload.comment}}
                                                </p>
                                            </slot>
                                        </div>
                                        <ul class="list">
                                            <li v-if="upload.isPausable()">
                                                <button @click.prevent="upload.pause" aria-label="Pause">
                                                    <IconLight icon="pause" />
                                                    <span>{{$root.l10n('upload_paused')}}</span>
                                                </button>
                                            </li>
                                            <li v-else-if="upload.isResumable()">
                                                <button @click.prevent="upload.resume" aria-label="Resume">
                                                    <IconLight icon="play" />
                                                    <span>{{$root.l10n('resume_upload')}}</span>
                                                </button>
                                            </li>
                                            <li v-else-if="upload.isRetryable()">
                                                <button @click.prevent="upload.retry" aria-label="Retry">
                                                    <IconSolid icon="sync" />
                                                    <span>{{$root.l10n('retry_upload')}}</span>
                                                </button>
                                            </li>
                                            <li v-if="upload.isCancelable()">
                                                <button  class='btn-icon-only' @click.prevent="upload.cancel" aria-label="Cancel">
                                                    <IconLight icon="times" />
                                                    <span>{{$root.l10n('cancel_upload')}}</span>
                                                </button>
                                            </li>
                                            <li v-if="!!upload.download()">
                                                <Download :src="upload.download()" tag="button" init="click" >
                                                    <IconLight icon="download" />
                                                    <span>{{$root.l10n('download')}}</span>
                                                </Download>
                                            </li>
                                            <slot name="action_else" :upload="upload">

                                            </slot>
                                            <li v-if="upload.isDeletable()">
                                                <button @click.prevent="upload.delete" aria-label="Delete">
                                                    <IconLight icon="trash"  />
                                                    <span>{{$root.l10n('remove_upload')}}</span>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </slot>
                    </slot>
                </li>
            </ul>
        </div>
        <slot name="default" />
    </div>
</template>

<script>
import qq from 'fine-uploader/lib/dnd'
import FineUploaderTraditional from 'fine-uploader-wrappers'
import {directive as ClickOutside} from 'click-outside-vue3'
import UploaderThumb from './UploaderThumb'
import ButtonStd from '../../forms/ButtonStd'
import IconLight from '../IconLight'
import IconSolid from '../IconSolid'
import ProgressBar from '../ProgressBar'
import { humanFileSize } from '../../../lib/utility'
import EventBus from '../../../lib/helpers/EventBus'
import Download from '../Download'
import {options} from '../../../lib/ajax'

/**
 * @param {number} id
 * @param {string} uuid
 * @param {object} file
 * @param {string} status
 * @method {boolean} isPausable()
 * @method {boolean} isResumable()
 * @method {boolean} isRetryable()
 * @method {boolean} isCancelable()
 * @method {boolean} isDeletable()
 * @method {boolean} isProcessing()
 * @method {boolean} isFailed()
 * @method {boolean} this.pause()
 * @method {boolean} this.resume()
 * @method {boolean} this.retry()
 * @method {boolean} this.cancel()
 * @method {boolean} this.delete()
 */
class Upload {
    constructor (id, uuid, file, status) {
        this.id = id
        this.uuid = uuid
        this.__status = status

        this.file = file
        this.comment = ''
        this.progress = 0
        this.fromServer = false
        this.thumb = null

        /* methods */

        this.isReady     = () => false
        this.isQueued    = () => false
        this.isSuccessful= () => false
        this.isPausable  = () => false
        this.isResumable = () => false
        this.isRetryable = () => false
        this.isCancelable= () => false
        this.isDeletable = () => false
        this.isProcessing= () => false
        this.isFailed    = () => false
        this.pause       = () => false
        this.resume      = () => false
        this.retry       = () => false
        this.cancel      = () => false
        this.delete      = () => false
        this.download    = () => null

        this.shown = false
        this.actionsHide = () => this.shown = false
        this.actionsToggle = () => this.shown = !this.shown
    }
    get status () {
        //console.log(this.id, this.uuid, 'status.get', this.__status);
        return this.__status || null
    }
    set status (v) {
        //console.log(this.id, this.uuid, 'status.set', v, 'old', this.__status)
        this.__status = v
    }
}

export const qqxStatus = {
    UPLOAD_CRASHED: "upload crashed"
    //, UPLOAD_SAVED: "upload saved"
    /* FineUploader.qq.status: */,
    SUBMITTING: "submitting",
    SUBMITTED: "submitted",
    REJECTED: "rejected",
    QUEUED: "queued",
    CANCELED: "canceled",
    PAUSED: "paused",
    UPLOADING: "uploading",
    UPLOAD_FINALIZING: "upload finalizing",
    UPLOAD_RETRYING: "retrying upload",
    UPLOAD_SUCCESSFUL: "upload successful",
    UPLOAD_FAILED: "upload failed",
    DELETE_FAILED: "delete failed",
    DELETING: "deleting",
    DELETED: "deleted"
}


export default {
        name: "Uploader",
        components: {
            Download,
            ProgressBar,
            UploaderThumb,
            ButtonStd,
            IconLight,
            IconSolid
        },
        props: {
            id: {
                type: String,
                default: 'upload'
            },
            endpoint: String,
            params: Object,
            autoUpload: {
                type: Boolean,
                default: false
            },
            multiple: {
                type: Boolean,
                default: false
            },
            additionalDropzone: {
                type: String,
                default: null
            },
            dropzoneDisabled: {
                default: true
            },
            thumbnailSize: {
                default: 60
            },
            retry: {
                type: Number,
                default: 3
            },
            disabled: Boolean,//todo:????
            acceptFiles: String,//accepted file types (.txt accept all text-files)
            allowedExtensions: Array,//accepted file extensions
            deleteEnabled: { default: false },
            deleteConfirm: { default: false },
            deleteEndpoint: String,
            label: String,
            files: Array //already existing files
        },
        data() {
            const files = this.convert2uploads(this.files, qqxStatus.UPLOAD_SUCCESSFUL)
            files.length && this.$emit('uploads', files)

            return {
                uploader: null,
                uploads: files,
                qqDropZone: null,
                progress: 0.00,
                filter: [],
                filterOptions: [
                    { label: this.$root.l10n('all'), state: [], count: 0, classes: 'alert alert-hover' },
                    { label: this.$root.l10n('successful'), state: [qqxStatus.UPLOAD_SUCCESSFUL], count: 0, classes: 'alert alert-hover alert-success' },
                    { label: this.$root.l10n('failed'), state: [qqxStatus.UPLOAD_FAILED,qqxStatus.UPLOAD_CRASHED,qqxStatus.DELETED], count: 0, classes: 'alert alert-hover alert-danger' }
                ].filter(o => !!o),
                qqxStatus: qqxStatus
            }
        },
        computed: {
            /**
             * @returns {Upload[]}
             */
            visibleFiles () {
                return this.filter.length ? this.uploads.filter(u => this.filter.indexOf(u.status) > -1) : this.uploads
            }
        },
        methods: {
            /**
             * @param {object[]} files
             * @param {string} status
             * @param {number} offset=1
             */
            convert2uploads (files, status, offset = 1) {
                return files ? files.map((file, index) => {
                    let upload
                    if (file instanceof Upload) {
                        upload = new Upload(-(index + offset), file.uuid, file.file, file.status || status)
                        Object.defineProperty(upload, 'thumb', {configurable: true, get: () => file.thumb}); //sync thumb
                    } else {
                        upload = new Upload(-(index + offset), null, file, file.status || status)
                    }
                    upload.isDeletable = () => file.deletable !== undefined ? !!file.deletable : true
                    upload.download = () => file.download ? file.download : null
                    upload.delete = () => this.deleteUpload(upload.id)

                    return upload
                }) : []
            },
            /**
             * @param {event} e
             */
            onFilesAdded (e) {
                this.uploader.methods.addFiles(e.target)
            },
            /**
             * @param {number} id
             * @param {string} oldStatus
             * @param {string} status
             */
            onStatusChange (id, oldStatus, status) {
                const qqxStatus = this.qqxStatus
                const uploads = this.uploads
                const finalize = () => {
                    this.countFilter()
                    this.$emit('uploads', this.uploads)
                }
                let upload = this.getUpload(id)
                if (!upload) {
                    uploads.push(upload = this.createUploadInstance(
                        id, this.getFileUuid(id), this.getFile(id), status
                    ))
                    if (!this.multiple) this.uploads.splice(0, this.uploads.length-1)
                    finalize()

                } else if (upload.status === qqxStatus.UPLOAD_CRASHED) {
                    //do nothing
                    finalize()
                } else if ([qqxStatus.CANCELED, qqxStatus.DELETED].indexOf(status) >= 0) {
                    this.__removeAnimated(upload).then(() => {
                        finalize()
                    })
                } else {
                    upload.status = status
                    upload.fromServer = status === qqxStatus.UPLOAD_SUCCESSFUL //k.a.
                    finalize()
                }
                /*
                this.$emit('deletable', id, upload.isDeletable())
                this.$emit('cancelable', id, upload.isCancelable())
                */
            },
            /**
             * @param {number} id
             * @param {string} uuid
             * @param {object} file
             * @param {string} status
             * @returns Upload
             */
            createUploadInstance(id, uuid, file, status) {
                const self = this
                const upload = new Upload(id, uuid, file, status)

                upload.shown = false
                upload.isReady     = function () { /*console.log('isReady',this.status); */return self.qqxStatus.SUBMITTED === this.status }.bind(upload)
                upload.isSuccessful= function () { /*console.log('isSuccessful',this.status); */return self.qqxStatus.UPLOAD_SUCCESSFUL === this.status }.bind(upload)
                upload.isQueued    = function () { /*console.log('isQueued',this.status); */return self.qqxStatus.QUEUED === this.status }.bind(upload)
                upload.isPausable  = function () { /*console.log('isPausable',this.status); */return self.isPausable(this.status) }.bind(upload)
                upload.isResumable = function () { /*console.log('isResumable',this.status); */return self.isResumable(this.status) }.bind(upload)
                upload.isRetryable = function () { /*console.log('isRetryable',this.status); */return self.isRetryable(this.status) }.bind(upload)
                upload.isCancelable= function () { /*console.log('isCancelable',this.status); */return self.isCancelable(this.status) }.bind(upload)
                upload.isDeletable = function () { /*console.log('isDeletable',this.status); */return self.isDeletable(this.status) }.bind(upload)
                upload.isProcessing= function () { /*console.log('isProcessing',this.status); */return self.isProcessing(this.status) }.bind(upload)
                upload.isFailed    = function () { /*console.log('isFailed',this.status); */return self.isFailed(this.status) }.bind(upload)
                upload.pause       = function () { self.uploader.methods.pauseUpload(this.id) }.bind(upload)
                upload.resume      = function () { self.uploader.methods.continueUpload(this.id) }.bind(upload)
                upload.retry       = function () { self.uploader.methods.retry(this.id) }.bind(upload)
                upload.cancel      = function () { self.uploader.methods.cancel(this.id) }.bind(upload)
                upload.delete      = function () { self.uploader.methods.deleteFile(this.id) }.bind(upload)

                return upload
            },
            /**
             */
            countFilter(){
                for(const opt of this.filterOptions){
                    opt.count = 0
                    this.uploads.forEach(function(upload) {
                        if (
                            opt.state.length === 0 ||
                            opt.state.indexOf(upload.deleteReason || upload.status) > -1
                        ) opt.count++;
                    })
                }
            },
            /**
             * @param {number} index
             */
            getFile(index) {
                return this.uploader.methods.getFile(index)
            },
            /**
             * @param {number} index
             */
            getFileUuid(index) {
                return this.uploader.methods.getUuid(index)
            },
            /**
             * @param {number} id
             */
            getUpload(id) {
                return this.uploads.find(u => u.id === id)
            },
            /**
             * @param {number} id
             */
            deleteUpload(id) {
                const upload = this.getUpload(id)
                if (!upload) return false

                return this.__removeAnimated(upload).then(() => {
                    if (upload.id >= 0) this.uploader.methods.deleteFile(id)
                    else this.$emit('deleted', id, true)
                    this.$emit('uploads', this.uploads)
                })
            },
            /**
             * @param {Upload} upload
             * @returns Promise
             */
            __removeAnimated(upload) {
                this.$refs['upload-item-' + upload.id][0].classList.add('removing')
                return new Promise((resolve) => {
                    setTimeout(() => {
                        //this.$refs['upload-item-' + upload.id][0].classList.remove('removing')
                        this.uploads = this.uploads.filter(u => u.id !== upload.id)
                        resolve();
                    }, 200)
                })
            },
            /**
             * @returns {number}
             */
            start () {
                console.log('start')
                const count = this.uploader.methods.getUploads({status: this.qqxStatus.SUBMITTED}).length
                if (count > 0) this.uploader.methods.uploadStoredFiles()
                return count
            },
            /**
             * @param {string} status
             * @returns {boolean}
             */
            isProcessing(status)
            {
                const qqStatus = this.qqxStatus
                return [
                    qqStatus.SUBMITTING,
                    qqStatus.UPLOADING,
                    qqStatus.UPLOAD_FINALIZING,
                    qqStatus.UPLOAD_RETRYING,
                    qqStatus.DELETING
                ].indexOf(status) >= 0
            },
            /**
             * @param {string} status
             * @returns {boolean}
             */
            isFailed(status)
            {
                const qqStatus = this.qqxStatus
                return [
                    qqStatus.UPLOAD_CRASHED,
                    qqStatus.UPLOAD_FAILED,
                    qqStatus.DELETE_FAILED
                ].indexOf(status) >= 0
            },
            /**
             * @param {string} status
             * @returns {boolean}
             */
            isPausable(status) {
                return this.qqxStatus.UPLOADING === status
            },
            /**
             * @param {string} status
             * @returns {boolean}
             */
            isResumable(status) {
                return this.qqxStatus.PAUSED === status
            },
            /**  */
            isRetryable(status) {
                const qqStatus = this.qqxStatus
                return [
                    qqStatus.DELETE_FAILED,
                    qqStatus.UPLOAD_FAILED
                ].indexOf(status) >= 0
            },
            /**
             * @param {string} status
             * @returns {boolean}
             */
            isCancelable(status) {
                const qqStatus = this.qqxStatus
                return [
                    qqStatus.DELETE_FAILED,
                    qqStatus.PAUSED,
                    qqStatus.QUEUED,
                    qqStatus.UPLOAD_RETRYING,
                    qqStatus.SUBMITTED,
                    qqStatus.UPLOADING,
                    qqStatus.UPLOAD_FAILED
                ].indexOf(status) >= 0
            },
            /**
             * @param {string} status
             * @returns {boolean}
             */
            isDeletable(status) {
                const qqStatus = this.qqxStatus
                return this.deleteEnabled && [
                    qqStatus.DELETE_FAILED,
                    qqStatus.UPLOAD_SUCCESSFUL
                ].indexOf(status) >= 0
            },
            visibilityFilterChange (filter) {
                this.filter = filter
            },
            focus() {
                this.$refs.input.click()
            },
            formatSize(value) {
                return humanFileSize(value, true, 2)
            }
        },
        directives: {
            ClickOutside
        },
        watch: {
            autoUpload(value) {
                this.uploader.methods._options.autoUpload = value
            },
            files(files) {
                const offset = Math.abs(Math.min(-1, ...this.uploads.map(u => u.id)))
                const uploads = this.convert2uploads(files, this.qqxStatus.UPLOAD_SUCCESSFUL, offset)
                    .filter(u => !this.uploads.some(u2 => u2?.file === u.file))

                if (uploads.length) {
                    if (this.multiple) this.uploads.push(...uploads)
                    else this.uploads = uploads
                    this.$emit('uploads', this.uploads)
                }
            }
        },
        mounted() {
            const dropzone = this.$refs.dropZone || this.$el

            //+ extra drop zones
            const additionalDropzoneEl = document.getElementsByClassName(this.additionalDropzone) || []
            const dropzones = [dropzone].concat([...additionalDropzoneEl])

            this.qqDropZone = new qq.DragAndDrop({
                allowMultipleItems: !!this.multiple,
                callbacks: {
                    dropError: (errorCode, errorData) => {
                        console.error(errorCode, errorData)
                    },
                    processingDroppedFiles: () => {
                        console.log('processingDroppedFiles', arguments)
                    },
                    processingDroppedFilesComplete: (files) => {
                        this.uploader.methods.addFiles(files)
                        console.log('processingDroppedFilesComplete', arguments)
                    }
                },
                classes: {
                    dropActive: 'qq-upload-drop-area-active'
                },
                dropZoneElements: dropzones
            })

            EventBus.$on('upload.start', this.start)

            // click on additionalDropzone to open file input dialog
            for(let zone of additionalDropzoneEl) {
                zone.addEventListener('click', () => {
                    this.$refs.input.click()
                })
            }
        },
        beforeUnmount() {
            this.qqDropZone && this.qqDropZone.dispose()
            this.uploader.methods.cancelAll()
            EventBus.$off('upload.start', this.start)
        },
        created() {
            const debug = this.$root.debug
            const $emit = (...args) => this.$emit(...args)
            const csrf = options.csrf
            this.uploader = new FineUploaderTraditional({options: {
                    debug: debug,
                    autoUpload: this.autoUpload,
                    params: this.params,
                    multiple: this.multiple,
                    request: {
                        endpoint: this.endpoint || this.$root.getRoutePath('system.uploader'),
                        customHeaders: {
                            "X-Csrf-Token": csrf
                        }
                    },
                    deleteFile: {
                        enabled: !!this.deleteEnabled,
                        forceConfirm: !!this.deleteConfirm,
                        endpoint: this.deleteEnabled &&
                            this.deleteEndpoint || this.$root.getRoutePath('system.uploaderDelete').slice(0, -1)
                    },
                    //dragAndDrop: { extraDropzones : [] },
                    retry:{
                        autoAttemptDelay: 3, //sec
                        maxAutoAttempts: 5,
                        enableAuto: false
                    },
                    folders: true,
                    chunking: {
                        enabled: true,
                        concurrent: false, //be careful, parts will be combined immediately, otherwise change server-side
                        partSize: 1048576
                    },
                    validation: {
                        acceptFiles: this.acceptFiles || null,
                        allowedExtensions : this.allowedExtensions || [],
                        allowEmpty: false
                    },
                    callbacks: {
                        onSubmit: (id, fileName) => {
                            const file = this.getFile(id)
                            if (debug) console.log('onSubmit', id, fileName, file)
                            const newParams = {
                                qqpath: file.qqPath
                            }

                            // avoid os system files like .DS_Store
                            if (!this.acceptFiles && !this.allowedExtensions && (
                                    file.name.startsWith('.') ||
                                    file.name.indexOf('.') === -1 ||
                                    file.name.toLowerCase() === 'thumbs.db' ||
                                    file.name.toLowerCase() === 'desktop.ini'
                                )
                            ) {
                                this.uploader.methods.cancel(id)
                                return false
                            }

                            this.uploader.methods.setParams(newParams, id)
                            this.$emit('add', id, file)
                        },
                        onUpload: (id, name) => {
                            if (debug) console.log('onUpload', id, name, this.getFile(id), this.getFileUuid(id), this.endpoint)
                            $emit('added', id, name, this.getFileUuid(id))
                        },
                        onComplete: (id, name, response) => {
                            const success = response.success || false;
                            const upload = this.getUpload(id)
                            if (debug) console.log('onComplete', id, name, success)
                            if (upload) upload.progress = 0
                            $emit('complete', id, name, success)
                        },
                        onCancel: (id, name) => {
                            if (debug) console.log('onCancel', id, name)
                            $emit('remove', id, this.getFile(id))
                            $emit('cancel', id, name)
                        },
                        onAllComplete: (succeeded, failed) => {
                            if (debug) console.log('onAllComplete', succeeded, failed)
                            //todo: $emit('allcomplete', succeeded, failed)
                        },
                        onDelete: (id) => {
                            if (debug) console.log('onDelete', id)
                            // $emit('delete', id, true)
                        },
                        onDeleteComplete: (id, xhr) => {
                            if (debug) console.log('onDeleteComplete', id)
                            const response = JSON.parse(xhr.response) || {}
                            const success = response.success || false
                            $emit('deleted', id, success)
                        },
                        onError: (id, name, errorReason, xhr) => {
                            if (debug) console.log('onError', id, name, errorReason, xhr)

                            // @hook to avoid continue, if response was corrupted
                            if (200 === xhr?.status) try {
                                JSON.parse(xhr.responseText)

                            } catch (e) {
                                errorReason = e.message;
                                const upload = this.uploads.find(u => u.id === id)
                                upload.status = this.qqxStatus.UPLOAD_CRASHED
                                upload.comment = /*options.messages.customJsonParseFailed || */'Response parse error, please try again'
                                this.uploader.methods.deleteFile(id)
                            }
                            $emit('error', id, name, errorReason)
                        },
                        onProgress: (id, name, uploadedBytes, totalBytes) => {
                            const upload = this.getUpload(id)
                            if (debug) console.log('onProgress', id, name, uploadedBytes, totalBytes)
                            if (upload) upload.progress = uploadedBytes / totalBytes * 100 || 0
                        },
                        onStatusChange: (id, oldStatus, newStatus) => {
                            if (debug) console.log('onStatusChange', id, oldStatus, newStatus)
                            this.onStatusChange(id, oldStatus, newStatus)
                            /*this.$emit('count', this.visibleFiles.length, this.uploads.length);*/
                        },
                        onTotalProgress: (totalUploadedBytes, totalBytes) => {
                            if (debug) console.log('onTotalProgress', totalUploadedBytes, totalBytes)
                            this.progress = totalUploadedBytes / totalBytes * 100 || 0
                        }
                    },
                    showMessage(message) {
                        alert('UPLOADER.VUE: ' + message)
                    }
                }
            })
        }
    }
</script>
<style>
    /* animation removing uploads */
    .qq-upload-list-selector > li {
        animation-duration: 0.3s;
        animation-iteration-count: 1;
        transform-origin: left;
        animation-name: slide-left-to-right;
        animation-timing-function: ease-in-out;
    }

    .qq-upload-list-selector > li.removing {
        transform: translateX(200%);
        transition: all 0.3s ease-in-out;
    }
</style>