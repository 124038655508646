class Routing {
  /**
   * @param {Array} routes
   */
  constructor(routes) {
    this.base = ''
    this.list = []
    this.push(...(routes || []));
  }

  /**
   * @returns {number}
   */
  get length() {
    return this.list.length;
  }

  /**
   * @param  {...Object} args
   */
  push(...args) {
    for (let i = 0; i < args.length; i++) {
      let route = new Route(args[i]);
      if (route.isCorrect()) {
        //route.prefix = this.base;
        this.list.push(route)
      }
    }
    return this.list.length;
  }

  /**
   * @param {String} name
   * @returns {Route}
   */
  findByName(name) {
    for (let i = 0, cn = this.list.length; i < cn; i++) {
      if (this.list[i].name === name) return this.list[i];
    }
    return null;
  }

  /**
   * @param {String} uri
   * @returns {?Route}
   */
  findByPath(uri) {
    let match;
    for (let i = 0, cn = this.list.length; i < cn; i++) {
      if (this.list[i].match(uri)) match = this.list[i];
    }
    return match ? match : null;
  }
  /**
   * @param {Route} route
   * @return {Route}
   */
  cloneBeforeReturn(route) {
    //const clone = route.clone()
    //route.args = {} //reset Route in Routing list
    //return clone
    return route
  }
  /**
   * @param {String} baseURI
   */
  setBase(baseURI){
    this.base = baseURI.replace(/^(.*)\/+$/, '$1') + '/';
    //for (let i = 0, cn = this.list.length; i < cn; i++) {
    //  this.list[i].prefix = this.base;
    //}
  }
  /**
   * @returns {Route}
   */
  getUnknownRoute(){
    return createFailedRoute();
  }
}
/**
 * @returns {Route}
 */
function createFailedRoute() {
  return new Route({
    name: "error",
    controller: "ErrorController",
    action: 'notFound'
  })
}

/**
 *
 */
class Route {
  static TYPE_PAGE = 0;
  static TYPE_ACTION = 1;

  /*
  protected $right;

  /**
   * @var string $dynamicTail
   * contains rest of URL that doesn't match to base path part
   * @example regular path = /some/path/$param/
   *      parsing URL = /some/path/$param/extra/tail
   *      dynamicTail = extra/tail
   *
   */
  /**
   * Route constructor.
   * @param {Object} route
   */
  constructor(route) {
    route = route || {}

    this.prefix = '';
    this.type = this.recognizeType(route.type || null);
    this.name = route.name || null;
    this.path = route.path || '';
    this.controller = route.controller || null;
    this.action = route.action || 'start';
    //this.session = route.session || null;
    this.access = route.access || 'protected';
    this.props = route.parameters || [];
    this.args = {}
    this.dynamicTail = '';
    //this.right = RouteRightCollection::buildFromArray(empty($route['right']) ? array() : $route['right']);
  }
  /**
   * @param {?Object} args
   * @return {Route}
   */
  clone(args = {}) {
    const route = new Route()
    route.prefix = this.prefix;
    route.type = this.type;
    route.name = this.name;
    route.path = this.path;
    route.controller = this.controller
    route.action = this.action;
    route.access = this.access;
    route.props = this.props;
    route.args = Object.assign({}, this.args, args);
    route.dynamicTail = this.dynamicTail;
    return route
  }
  
  /**
   * @param {String} typeStr
   * @return {Number}
   * @deprecated
   */
  recognizeType(typeStr) {
    switch ((typeStr || '').toLowerCase()) {
      case 'action':
      case this.TYPE_ACTION:
        return this.TYPE_ACTION;
      default:
        return this.TYPE_PAGE;
    }
  }

  /**
   * check if route is correct
   * @return {Boolean}
   */
  isCorrect() {
    return (this.name && this.path && this.controller && this.action);
  }

  /**
   * match route with url
   * @param {String} url
   * @return {Boolean}
   */
  match(url) {

    //console.log('match // incoming url ', url)
    if (!this.isCorrect()) return false;

    let args = this.args = {};
    let path = this.replaceParamInPath(url);
    //console.log('match // founded path ', path || 'unknown')
    if (!path || url.indexOf(path) !== 0) return false;

    let param = url.substr(path.length);
    if (param.substr(-1) === '/') param = param.substr(0, param.length - 1);
    param = param.length === 0 ? [] : param.split('/');
    //console.log('match // param', param)

    if ((args = this.parsePathTail(param))) {
      //console.log( 'match // ok');

      this.args = args;
      this.dynamicTail = url.substr(path.length);
      return true;
    } else return false;
  }

  /**
   * @protected
   * @param {String} url
   * @return {String}
   */
  replaceParamInPath(url) {
    let pos;
    let path = this.prefix + this.path;
    let urllen = url.length;
    loop: while ((pos = path.indexOf('$')) !== -1) {

      let pos1 = path.indexOf('/', pos);
      if (pos1 === -1) pos1 = path.length;

      let argName = path.substr(pos + 1, pos1 - pos - 1);

      for (let param of this.props) {
        if (argName !== param.name) continue;

        let pos2 = url.indexOf('/', Math.min(urllen, pos));
        if (pos2 === -1) pos2 = urllen;
        let val = url.substr(pos, pos2 - pos);

        //if there variable as value - need to refuse match-function
        if ('$' + argName === val) return false;
        if ((typeof param.value !== 'undefined') && param.value !== val) break;
        if ((val = this.castValueByType(val, param.type)) === null) break;

        path = path.substr(0, pos) + val + path.substr(pos1);
        this.args[argName] = val;
        continue loop;
      }
      //no matches
      return this.prefix + this.path;
    }
    return path;
  }

  /**
   * set parameters to self-route if they match with pattern
   * @protected
   * @params {Array} params - parameters
   * @return {?Object}
   */
  parsePathTail(params) {
    //console.log('parsePathTail', JSON.stringify(params));
    let outputParam = {}
    let propsCn = this.props.length;
    let paramsCn = Object.keys(params).length;
    let allowEndlessParams = false;

    if (propsCn === 0 && paramsCn === 0) return {};
    else if (propsCn === 0 && paramsCn > 0) return null;

    //console.log('parsePathTail // loop ', propsCn);
    for (let i = 0, j = 0; i < propsCn; i++) {
      const prop = this.props[i];

      if (typeof prop.value !== 'undefined') {
        //console.log('parsePathTail // Constant ', i, prop.name, prop.value)
        outputParam[prop.name] = prop.value;
        paramsCn++;
        continue;
      }

      let key = prop.name;
      let val = typeof this.args[key] === 'undefined' ?
        typeof params[j] === 'undefined' ? false : params[j++] : this.args[key];

      //console.log('parsePathTail // ', i, key, val);
      if (key === '*' && i === propsCn - 1) {
        allowEndlessParams = true;
        continue;

      } else if (val === false && prop.required) {
        return null;

      } else if (val === false) {
        params[key] = val;
        continue;
      }

      //console.log('parsePathTail // ', i, key, val, prop.type);

      //check type of param
      if ((val = this.castValueByType(val, prop.type)) === null) return false;
      //console.log('parsePathTail // gecasted', val, prop.type);

      //check by regEx
      if (typeof prop.regex !== "undefined") {
        let regex = new RegExp(prop.regex, 'is');
        //console.log('parsePathTail // regex', regex, params[j - 1]);

        if (!regex.test(params[j - 1])) {
          //throw new SyntaxError('incorrect regular expression: ' + prop.regex);
          return false;
        }

      }
      //set param
      outputParam[key] = val;
    }
    //console.log('parsePathTail // outputParam', outputParam, allowEndlessParams ? 'allowEndlessParams' : 'NOT allowEndlessParams');
    //console.log('parsePathTail // ', paramsCn , outputParam.length, allowEndlessParams, paramsCn > outputParam.length  && !allowEndlessParams ? 'return true' : 'return false')

    //if parameters after to much, refuse match-function
    if (paramsCn > Object.keys(outputParam).length && !allowEndlessParams) return false;
    return outputParam;
  }
  
  /**
   * returns prepared uri depends on args
   * @retuns {String}
   */
   get argsPath () {
    return this.makePath(this.prefix + this.path, this.args);
  }

  /**
   * prepare routing, set arguments and return web-path to resource
   * @params {Object|...any} args - parameters
   * @return {Route}
   * @see prepareArgs
   */
  prepare(...args) {
    this.args = this.prepareArgs(...args);
    return this
  }
  /**
   * return web-path to resource
   * @params {Object|...any} args - parameters
   * @return {String}
   * @see prepareArgs
   * @see makePath
   */
  preparePath(...args) {
    let params = this.prepareArgs(...args);
    if (null === params) return '/#';
    return this.makePath(this.prefix + this.path, params);
  }

  /**
   * return web-path to resource
   * @protected
   * @params {String} pattern - path pattern
   * @params {Object} params - valid arguments objects
   * @return {String}
   * @example path: /path/$a1/$a2/ call preparePath({a1: 1, a2: 2}) || preparePath(1, 2})
   */
  makePath(pattern, params) {
    let path = pattern;
    let paramKeys = params ? Object.keys(params) : [];
    for (let i = 0, cn = paramKeys.length; i < cn; i++) {
      let key = paramKeys[i];
      let val = params[key];
      let inPath = path.indexOf('$' + key) !== -1;
      let prop = this.props.find(p => p.name === key) || {}
      if (prop.type === 'static') continue

      if (inPath) {
        path = path.replace('$' + key, val);
      } else if (val !== null) {
        path = path.replace(/^(.*)\/$/, '$1') + '/' + val
          + ((i === cn - 1 && String(val).indexOf('.') !== -1 && prop.type !== 'double') ? '' : '/');
      }
    }
    return path;
  }

  /**
   * maps arguments with properties, returns arguments or NULL if failed
   * @params {Object|...any} params - parameters
   * @return {?Object}
   */
  prepareArgs(...params) {
    let path = this.prefix + this.path;
    let args = {};
    if (params.length === 1 && typeof params[0] === 'object') params = params[0]

    for (let i = 0, k = 0, cn = this.props.length; i < cn; i++) {
      let prop = this.props[i];
      let key = prop.name;
      let inPath = path.indexOf('$' + key) !== -1;
      let val = typeof prop.value === 'undefined' ?
        !(params && typeof params[key] !== 'undefined' && params[key] !== null) ?
          !(params && typeof params[k] !== 'undefined' && params[k] !== null) ? null :
            String(params[k++]) : String(params[key]) : prop.value;

      if (val === null && (prop.required || inPath)) {
        //val = new Error('required value');
        return null;

      } else if (val !== null && (val = this.castValueByType(val, prop.type)) === null) {
        //val = new TypeError('value type mismatch');
        return null;
      } else args[key] = val;
    }
    return args;
  }

  /**
   * caste value by type. if type could not be converted returns NULL
   * @protected
   * @param {String} val
   * @param {String} type
   * @return {String|Number|Float|Boolean}
   */
  castValueByType(val, type) {
    switch (type) {
      case 'int':
        if (isNaN(val) || parseInt(val, 10) != val) val = null;
        else val = parseInt(val, 10);
        break;

      case 'double':
        if (isNaN(val) || parseFloat(val) != val) val = null;
        else val = parseFloat(val);
        break;

      case 'boolean':
      case 'bool':
        if (val === 'true' || val === true || val === '1' || val === 1) val = true;
        else if (val === 'false' || val === false || val === '0' || val === 0) val = false;
        else val = null;
        break;

      case 'string':
      default:
        val = String(val);
    }
    return val;
  }
}

module.exports = {
  Routing,
  Route
}